import { Divider } from "components/atoms/Divider";
import { Heading } from "components/atoms/Heading";
import { Stack } from "components/atoms/Stack";
import {
  BulkUploadColumnName,
  BulkUploadItemFieldNames,
} from "enum/bulk-upload-column-names.enum";
import { PackageErrorCodes } from "enum/error-codes/package-error-codes.enum";
import { IShipment } from "models/shipment/shipment.model";
import { ReactNode } from "react";
import {
  convertDatabaseToValue,
  convertGramsToKilos,
} from "utils/convertGramsToKilos";
import { formatNumberWithCommas } from "utils/formatNumberWithCommas";
import {
  hasCarrierErrors,
  hasLabelErrors,
  hasPackageErrors,
  hasRuleErrors,
  hasConsignmentErrors,
  hasZoneErrors,
  isItemError,
  isShipmentError,
  PrintConsignmentItemErrors,
  PrintConsignmentShipmentErrors,
  PrintLabelErrors,
  PrintRuleErrors,
  UNHANDLED_ERROR_MESSAGE,
} from "./ShipmentErrors.utils";
import { Error } from "components/atoms/Error";
import { ErrorBoundary } from "components/atoms/ErrorBoundary";

const ShipmentErrorsSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div>
      <Heading size="base" className="mb-1">
        {title}
      </Heading>
      <Divider className="my-4" />
      {children}
    </div>
  );
};

export const ShipmentErrors = ({
  shipment,
}: {
  shipment: IShipment | null;
}) => {
  if (shipment) {
    const packageErrors = [];
    shipment.packages.forEach((p) => {
      p.errors.forEach((e) => {
        if (e.code === "INVALID_HS_CODE") {
          if (!packageErrors.includes("HS code was rejected by the carrier.")) {
            packageErrors.push("HS code was rejected by the carrier.");
          }
        } else if (e.code === "INVALID_EORI_NUMBER") {
          if (
            !packageErrors.includes("EORI number was rejected by the carrier.")
          ) {
            packageErrors.push("EORI number was rejected by the carrier.");
          }
        } else {
          e.raw.forEach((r) => {
            if (!packageErrors.includes(r.message)) {
              packageErrors.push(r.message);
            }
          });
        }
      });
    });
    return (
      <ErrorBoundary
        FallbackComponent={() => {
          return <Error />;
        }}
      >
        <Stack divider={<Divider />} spacing={4}>
          {hasConsignmentErrors(shipment.errors) && (
            <ShipmentErrorsSection title="Consignment errors">
              {Object.keys(shipment.errors.consignment).map((p, i) => {
                const errorCode = shipment.errors.consignment[p];

                return (
                  <div key={i}>
                    {isShipmentError(p as BulkUploadColumnName) ? (
                      <>
                        {p}:{" "}
                        {PrintConsignmentShipmentErrors(
                          errorCode[0].code,
                          shipment[p]
                        )}{" "}
                      </>
                    ) : isItemError(p as BulkUploadItemFieldNames) ? (
                      PrintConsignmentItemErrors(
                        errorCode,
                        shipment.itemDescriptions[0]
                      )
                    ) : (
                      UNHANDLED_ERROR_MESSAGE
                    )}
                  </div>
                );
              })}
            </ShipmentErrorsSection>
          )}

          {hasLabelErrors(shipment?.packages) && (
            <ShipmentErrorsSection title="Label errors">
              {packageErrors.map((error, i) => {
                return <div key={i}>{error}</div>;
              })}

              {/* The above is a temporary solution until errors are 
              properly coming through then go back to using bottom method*/}

              {/* {shipment.packages.map((p, i) => {
                return (
                  <div key={i}>
                    {p.errors.map((error, key) => {
                      console.log(error);

                      return (
                        <div key={key}>{PrintLabelErrors(error.code)}</div>
                      );
                    })}
                  </div>
                );
              })} */}
            </ShipmentErrorsSection>
          )}

          {hasRuleErrors(shipment?.errors) && (
            <ShipmentErrorsSection title="Rule errors">
              {shipment.errors.rule.map((error, i) => {
                return <div key={i}>{PrintRuleErrors(error.code)}</div>;
              })}
            </ShipmentErrorsSection>
          )}

          {hasZoneErrors(shipment?.errors) && (
            <ShipmentErrorsSection title="Zone errors">
              {shipment.errors.zone.map((error, i) => {
                return <div key={i}>{error.code}</div>;
              })}
            </ShipmentErrorsSection>
          )}

          {hasCarrierErrors(shipment?.errors) && (
            <ShipmentErrorsSection title="Carrier errors">
              {shipment.errors.carrier.map((error, i) => {
                return <div key={i}>{error.code}</div>;
              })}
            </ShipmentErrorsSection>
          )}

          {hasPackageErrors(shipment.errors) && (
            <ShipmentErrorsSection title="Package errors">
              {shipment.errors.packages.map((p, i) => {
                console.log("p", p);
                const itemExceedsPackageString =
                  PackageErrorCodes.ITEM_WEIGHT_EXCEEDS_PACKAGE_WEIGHT.toLowerCase().replaceAll(
                    "_",
                    " "
                  );
                return (
                  <div key={i}>
                    <div>
                      {p.errors.limits.map((packageError) => {
                        return (
                          <div key={i}>
                            {packageError.code}
                            {/* {packageError.code ===
                            PackageErrorCodes.PACKAGE_DIMENSIONS_EXCEEDS_LIMIT
                              ? `Dimensions (Length x Width x Height) ${formatNumberWithCommas(
                                  convertDatabaseToValue(
                                    packageError.props.value
                                  )
                                )}cm exceed the limit of ${formatNumberWithCommas(
                                  convertDatabaseToValue(
                                    packageError.props.limit
                                  )
                                )}cm`
                              : PackageErrorCodes.PACKAGE_WEIGHT_EXCEEDS_LIMIT
                              ? `Weight ${formatNumberWithCommas(
                                  convertGramsToKilos(
                                    convertDatabaseToValue(
                                      packageError.props.value
                                    )
                                  )
                                )}kg exceed the limit of ${formatNumberWithCommas(
                                  convertGramsToKilos(
                                    convertDatabaseToValue(
                                      packageError.props.limit
                                    )
                                  )
                                )}kg`
                              : PackageErrorCodes.PACKAGE_GIRTH_EXCEEDS_LIMIT
                              ? `Girth (Width x Height * 2) ${formatNumberWithCommas(
                                  convertDatabaseToValue(
                                    packageError.props.value
                                  )
                                )}cm exceed the limit of ${formatNumberWithCommas(
                                  convertDatabaseToValue(
                                    packageError.props.limit
                                  )
                                )}`
                              : `Length ${formatNumberWithCommas(
                                  convertDatabaseToValue(
                                    packageError.props.value
                                  )
                                )}cm exceed the limit of ${formatNumberWithCommas(
                                  convertDatabaseToValue(
                                    packageError.props.limit
                                  )
                                )}cm`} */}
                          </div>
                        );
                      })}
                      {p.errors?.packageWeight?.length ? (
                        <>
                          {p.errors?.packageWeight[0]?.code ===
                          PackageErrorCodes.ITEM_WEIGHT_EXCEEDS_PACKAGE_WEIGHT
                            ? itemExceedsPackageString.charAt(0).toUpperCase() +
                              itemExceedsPackageString.slice(1) +
                              "."
                            : null}
                        </>
                      ) : (
                        <>
                          {p.packageItems[0]?.errors?.itemWeight &&
                          p.packageItems[0]?.errors?.itemWeight[0]?.code ===
                            PackageErrorCodes.ITEM_WEIGHT_EXCEEDS_PACKAGE_WEIGHT
                            ? itemExceedsPackageString.charAt(0).toUpperCase() +
                              itemExceedsPackageString.slice(1) +
                              "."
                            : null}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </ShipmentErrorsSection>
          )}
        </Stack>
      </ErrorBoundary>
    );
  }

  return <></>;
};
