import {
  PickUpObjectType,
  DropoffObjectType,
  SetDropOffPayload,
  CreateAddress,
  FoundDropOffLocations,
} from "store/createShipmentSlice/createShipmentSlice.types";
import { Checkbox } from "components/atoms/Checkbox";
import { FormControl } from "components/molecules/FormControl";
import { DatePickerComponent } from "components/atoms/datePicker";
import { TimePickerRangeComponent } from "components/atoms/timePickerRange";
import { Input } from "components/atoms/Input";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { RootState } from "store";
import { DropOffSelect } from "components/molecules/DropOffSelect";
import { setSelectedLocation } from "store/createShipmentSlice/index.slice";

interface Props {
  setPickUp: (values: boolean) => void;
  setDropOff: (values: SetDropOffPayload) => void;
  setPickUpDate: (values: string) => void;
  setPickUpFromTime: (values: string) => void;
  setPickUpToTime: (values: string) => void;
  //setSelectedLocation: (values: FoundDropOffLocations) => void;
  setPickUpInstructions: (values: string) => void;
  pickUpObject: PickUpObjectType;
  dropOffObject: DropoffObjectType;
  shouldAllowPickUpForm: boolean;
  sender: CreateAddress;
}
// { setPickUpValues, pickUpObject }: Props

export const PickUpForm = (props: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [selectedDropOff, selectDropOff] =
    useState<FoundDropOffLocations | null>(null);
  const { salesAccountUUID, financialCompany } = useSelector(
    (state: RootState) => {
      return state.authSlice;
    }
  );
  const {
    pickUpObject: {
      shouldPickUp,
      pickupDate,
      pickupFromTime,
      pickupInstructions,
      pickupToTime,
    },
    dropOffObject: { shouldDropOff },
    setPickUp,
    setDropOff,
    sender,
    setPickUpDate,
    setPickUpFromTime,
    setPickUpToTime,
    setPickUpInstructions,
    shouldAllowPickUpForm,
  } = props;

  const dispatch = useDispatch();

  dayjs.extend(customParseFormat);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTimeRangeChange = (time, timeString) => {
    const addHours = (date, hours) => {
      date.setHours(date.getHours() + hours);

      return date;
    };

    const newDate = new Date(pickupDate).toISOString().split("T")[0];
    const fromTime = new Date().toISOString().split("T")[1];
    const toTime = addHours(new Date(), 4).toISOString().split("T")[1];
    dispatch(
      setPickUpFromTime(
        time ? time[0]["$d"].toISOString() : newDate + "T" + fromTime
      )
    );
    dispatch(
      setPickUpToTime(
        time ? time[1]["$d"].toISOString() : newDate + "T" + toTime
      )
    );
  };
  const handleDropOffChange = (checked: boolean) => {
    dispatch(
      setDropOff({
        shouldDropOff: checked,
        sender,
        salesAccountUUID,
      })
    );
  };

  return (
    <div>
      {!shouldAllowPickUpForm ? (
        <div className="text-sm text-gray10 mb-2">
          The selected service is eligible for pickup.
        </div>
      ) : null}
      <div className="flex  ">
        <div className="flex pr-10">
          <FormControl label="">
            <div style={{ width: "400px" }}>
              <Checkbox
                checked={shouldPickUp}
                onCheckedChange={(checked) => {
                  dispatch(setPickUp(checked as boolean));
                }}
                // disabled={!shouldAllowPickUpForm}
              >
                Request pickup
              </Checkbox>
              <div className="flex items-center mb-2 mt-4">
                <FormControl label="Pickup Date" isRequired={false}>
                  <DatePickerComponent
                    showTimeSelect={false}
                    date={new Date(pickupDate)}
                    setDate={(date) => {
                      dispatch(setPickUpDate(date.toISOString()));
                    }}
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    disabled={!shouldPickUp}
                  />
                </FormControl>
              </div>
              <div className="flex items-center mb-2">
                <FormControl label="Time Range (Local Time)" isRequired={false}>
                  <TimePickerRangeComponent
                    value={[
                      dayjs(dayjs(pickupFromTime).format("HH:mm"), "HH:mm"),
                      dayjs(dayjs(pickupToTime).format("HH:mm"), "HH:mm"),
                    ]}
                    onChange={handleTimeRangeChange}
                    disabled={!shouldPickUp}
                  />
                </FormControl>
              </div>
              <div className="items-center mb-2 w-full">
                <FormControl
                  label="Pickup Instructions"
                  isRequired={false}
                  // testId={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ITEM_COMMODITY_CODE}_${number}`}
                  className="flex-grow"
                >
                  <Input
                    value={pickupInstructions}
                    onChange={(e) => {
                      dispatch(setPickUpInstructions(e.target.value));
                    }}
                    ref={ref}
                    isDisabled={!shouldPickUp}
                  />
                </FormControl>
              </div>
            </div>
          </FormControl>
        </div>
        {financialCompany == 8 && (
          <div className="flex ">
            <FormControl label="">
              <Checkbox
                checked={shouldDropOff}
                onCheckedChange={(checked) =>
                  handleDropOffChange(checked as boolean)
                }
              >
                Request Drop off
              </Checkbox>
              <FormControl label="Drop off locations">
                <div style={{ width: 450 }}>
                  <DropOffSelect
                    onChange={(value) => {
                      selectDropOff(value);
                      dispatch(setSelectedLocation(value));
                    }}
                    value={selectedDropOff}
                  />
                </div>
              </FormControl>
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
};
