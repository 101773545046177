import { KeyboardKeys } from "enum/keyboardkeys.enum";
import { useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDisclosure } from "hooks/useDisclosure";
import { Close20 } from "@carbon/icons-react";
import { Center } from "../Center";
import { useOnClickOutside } from "hooks/useOutsideClick";
import { HiSearch } from "react-icons/hi";
import { Input } from "../Input";
import { FaSearch } from "react-icons/fa";

export const TableSearch = ({
  handleSubmit,
  records,
}: {
  handleSubmit(query: string): void;
  records: number;
}) => {
  const [query, setQuery] = useState<string>("");

  const ref = useHotkeys<HTMLInputElement>(
    KeyboardKeys.ENTER,
    () => {
      handleSubmit(query);
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const { onOpen, onClose } = useDisclosure();

  const container = useRef<HTMLDivElement>(null);

  useOnClickOutside([container], () => {
    onClose();
  });

  const handleClear = () => {
    setQuery("");
    handleSubmit("");
    ref?.current?.focus();
  };

  return (
    <div className="relative" ref={container}>
      <div className="absolute left-0 opacity-50 w-9 h-9">
        <Center>
          <HiSearch />
        </Center>
      </div>
      <div className="relative">
        <div className="absolute top-0 left-0 h-9 w-9 text-muted">
          <Center>
            <FaSearch />
          </Center>
        </div>
        <Input
          placeholder={`Search ${records || 0} records`}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          hasLeftElement={true}
          onFocus={onOpen}
          ref={ref}
        />
      </div>
      {query.length > 0 && (
        <div
          className="absolute top-0 right-0 w-10 cursor-pointer h-9"
          onClick={handleClear}
        >
          <Center>
            <Close20 />
          </Center>
        </div>
      )}
    </div>
  );
};
