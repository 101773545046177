import { AppThunk, RootState } from "store";
import { cancelPickup } from "services/api/shipments";
import { closeModal } from "store/modalSlice";
import { fetchShipmentsWithPickupThunk } from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";
import { PickupShipmentRequestDto } from "services/api/shipments/dtos/pickup-shipment.request.dto";
import toast from "react-hot-toast";

export const cancelPickUpRequestThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID, financialCompany },
      pickupSlice: { selectedShipment },
    } = getState() as RootState;
    let body: PickupShipmentRequestDto;

    const shipmentIDs: number[] = selectedShipment.shipments.map((shipment) => {
      return parseInt(shipment.shipmentId.toString());
    });

    if (selectedShipment.carrier.toLowerCase() === "fedex") {
      body = {
        FinancialCompany: financialCompany,
        Pickups: {
          shipmentIDs: shipmentIDs,
        },
      };
    } else {
      body = {
        shipmentIDs: shipmentIDs,
      };
    }

    const { data }: any = await cancelPickup(salesAccountUUID, body);
    if (!data.error) {
      dispatch(fetchShipmentsWithPickupThunk());
      dispatch(closeModal({ modalName: "viewPickupModal" }));
      dispatch(
        closeModal({
          modalName: "confirmCancelPickup",
        })
      );
      toast.success("Pickup Cancelled");
    } else {
      toast.error(data.message.message);
    }
  };
};
