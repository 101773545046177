import {
  setCreatePickupQueryFromDate,
  setCreatePickupQueryToDate,
  setCreatePickupDateRangeDays,
  setViewPickupQueryFromDate,
  setViewPickupQueryToDate,
  setViewPickupDateRangeDays,
  IPickupSlice,
} from "store/pickupSlice/index.slice";
import { calculateDaysBetweenDates } from "utils/calculate/calculateDaysBetweenDates.util";
import {
  fetchEligiblePickUpShipmentsThunk,
  fetchShipmentsWithPickupThunk,
} from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";
import { AppThunk } from "store";

export const changeCreateDateRangeFilterThunk = (
  fromDate: IPickupSlice["query"]["filters"]["fromDate"],
  toDate: IPickupSlice["query"]["filters"]["toDate"]
): AppThunk => {
  return async (dispatch) => {
    let from = fromDate;
    let to = toDate;
    //prevents people from setting the from after the to
    if (toDate < fromDate) {
      from = toDate;
      to = fromDate;
    }
    await dispatch(setCreatePickupQueryFromDate(from));
    await dispatch(setCreatePickupQueryToDate(to));
    const daysBetweenDates = calculateDaysBetweenDates(
      new Date(from),
      new Date(to)
    );

    let dateRange: "1d" | "7d" | "30d" | "1y" | "None" = "None";

    const isLeapYear = (year) => new Date(year, 1, 29).getMonth() === 1;

    if (daysBetweenDates === 0) {
      dateRange = "1d";
    } else if (daysBetweenDates === 7) {
      dateRange = "7d";
    } else if (daysBetweenDates === 30) {
      dateRange = "30d";
    } else if (daysBetweenDates === (isLeapYear(new Date()) ? 366 : 365)) {
      dateRange = "1y";
    }
    dispatch(fetchEligiblePickUpShipmentsThunk());
    dispatch(setCreatePickupDateRangeDays(dateRange));
  };
};

export const changeViewDateRangeFilterThunk = (
  fromDate: IPickupSlice["query"]["filters"]["fromDate"],
  toDate: IPickupSlice["query"]["filters"]["toDate"]
): AppThunk => {
  return async (dispatch) => {
    let from = fromDate;
    let to = toDate;
    //prevents people from setting the from after the to
    if (toDate < fromDate) {
      from = toDate;
      to = fromDate;
    }
    await dispatch(setViewPickupQueryFromDate(from));
    await dispatch(setViewPickupQueryToDate(to));
    const daysBetweenDates = calculateDaysBetweenDates(
      new Date(from),
      new Date(to)
    );

    let dateRange: "1d" | "7d" | "30d" | "1y" | "None" = "None";

    const isLeapYear = (year) => new Date(year, 1, 29).getMonth() === 1;

    if (daysBetweenDates === 0) {
      dateRange = "1d";
    } else if (daysBetweenDates === 7) {
      dateRange = "7d";
    } else if (daysBetweenDates === 30) {
      dateRange = "30d";
    } else if (daysBetweenDates === (isLeapYear(new Date()) ? 366 : 365)) {
      dateRange = "1y";
    }
    dispatch(fetchShipmentsWithPickupThunk());
    dispatch(setViewPickupDateRangeDays(dateRange));
  };
};
