import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { fetchCompanyAddresses } from "services/api/companyAddresses";
import { fetchCompanyContacts } from "services/api/companyContacts";
import { fetchSettings } from "services/api/settings";
import { AppThunk, RootState } from "store";
import { ISettingsSlice } from "./settingsSlice.interface";
import { SettingsSliceInitialState } from "./settingsSliceInitialState.const";

export const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState: SettingsSliceInitialState,
  reducers: {
    setSettingsData: (state, action: PayloadAction<ISalesAccount>) => {
      state.salesAccount = action.payload;
      state.settings = action.payload.defaultSettings;
      state.loadingBaseStatus = ApiLoadingStatus.SUCCESS;
    },
    setSettingsLoadingStatus: (
      state,
      action: PayloadAction<ApiLoadingStatus>
    ) => {
      state.loadingBaseStatus = action.payload;
    },
    setCompanyAddresses: (
      state,
      action: PayloadAction<ISettingsSlice["companyAddresses"]>
    ) => {
      state.companyAddresses = action.payload;
      state.loadingCompanyAddressesStatus = ApiLoadingStatus.SUCCESS;
    },
    setCompanyAddressesLoadingStatus: (
      state,
      action: PayloadAction<ApiLoadingStatus>
    ) => {
      state.loadingCompanyAddressesStatus = action.payload;
    },
    setCompanyContacts: (
      state,
      action: PayloadAction<ISettingsSlice["companyContacts"]>
    ) => {
      state.companyContacts = action.payload;
      state.loadingCompanyContactsStatus = ApiLoadingStatus.SUCCESS;
    },
    setCompanyContactsLoadingStatus: (
      state,
      action: PayloadAction<ApiLoadingStatus>
    ) => {
      state.loadingCompanyContactsStatus = action.payload;
    },
  },
});

export const {
  setSettingsData,
  setSettingsLoadingStatus,
  setCompanyAddresses,
  setCompanyAddressesLoadingStatus,
  setCompanyContacts,
  setCompanyContactsLoadingStatus,
} = settingsSlice.actions;

export const fetchSettingsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setSettingsLoadingStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    const { data, error } = await fetchSettings(salesAccountUUID);

    if (!error && data) {
      dispatch(setSettingsData(data));
    }

    if (error) {
      dispatch(setSettingsLoadingStatus(ApiLoadingStatus.FAILED));
    }
  };
};

export const fetchCompanyAddressesThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setCompanyAddressesLoadingStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const { data, error } = await fetchCompanyAddresses(salesAccountUUID);

      if (!error || data) {
        dispatch(setCompanyAddresses(data));
      }

      if (error) {
        dispatch(setCompanyAddressesLoadingStatus(ApiLoadingStatus.FAILED));
      }
    }
  };
};

export const fetchCompanyContactsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setCompanyContactsLoadingStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const { data, error } = await fetchCompanyContacts(salesAccountUUID);

      if (!error || data) {
        dispatch(setCompanyContacts(data));
      }

      if (error) {
        dispatch(setCompanyContactsLoadingStatus(ApiLoadingStatus.FAILED));
      }
    }
  };
};
