import { AppThunk, RootState } from "store";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import {
  fetchPickupShipments,
  fetchEligiblePickupShipments,
} from "services/api/shipments";
import { ShipmentStatusCategories } from "constants/shipment-statuses.config";
import {
  setStatus,
  setData,
  IPickupData,
  setShipmentsWithPickup,
} from "store/pickupSlice/index.slice";
import { IShipment } from "models/shipment/shipment.model";

export const fetchEligiblePickUpShipmentsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID },
      pickupSlice,
      tableSlice: { numberOfRowsSelected },
    } = getState() as RootState;

    const {
      offset,
      filters: { search, fromDate, toDate },
      sort,
    } = pickupSlice.query;
    // fromDate should always be at 00:00:00 timestamp
    const adjustedFromDate = `${fromDate.split("T")[0]}T00:00:00.000Z`;
    // toDate should always be at 23:59:59 timestamp
    const adjustedToDate = `${toDate.split("T")[0]}T23:59:59.000Z`;
    if (salesAccountUUID) {
      const { data, error, meta } = await fetchEligiblePickupShipments(
        salesAccountUUID,
        {
          offset,
          filters: {
            statuses: ShipmentStatusCategories.COMPLETE,
            ...(search && { search }),
            ...(fromDate && { fromDate: adjustedFromDate }),
            ...(toDate && { toDate: adjustedToDate }),
          },
          sort,
          limit: numberOfRowsSelected.value,
        }
      );

      const formatData = (addresses, arr) => {
        const obj = addresses.map((Address, index) => {
          const shipments = [];
          arr.forEach((element) => {
            if (element.fromAddress === Address) {
              let quantity = 0;
              let weight = 0;
              element.packages.forEach((p) => {
                quantity = quantity + p.quantity;
                const packageTotalWeight = p.quantity * p.weight;
                weight = weight + packageTotalWeight;
              });
              shipments.push({
                shipmentId: element.shipmentId,
                selected: false,
                address: element.toAddress,
                recepient: element.recipient,
                service: element.service.name,
                containsPickup: element.containsPickup,
                tracking: element.trackingCodes.join(","),
                quantity,
                weight: (weight / 100 / 1000).toFixed(2),
                createdDate: element.createdDate,
                reference: element.customerReference,
                prnNumber: element.upsPickupPrnNumber
                  ? element.upsPickupPrnNumber
                  : element.fedexPickupPrnNumber
                  ? element.fedexPickupPrnNumber
                  : null,
              });
            }
          });
          return {
            id: index + 1,
            Address,
            hide: true,
            shipments,
          };
        });
        return obj;
      };

      const ups = data.filter((element) => element.carrier.id === "16");
      const fedex = data.filter((element) => element.carrier.id === "24");
      const upsAddresses: string[] = [];
      const fedexAddresses: string[] = [];
      ups.forEach((element) => {
        if (!upsAddresses.includes(element.fromAddress)) {
          upsAddresses.push(element.fromAddress);
        }
      });

      fedex.forEach((element) => {
        if (!fedexAddresses.includes(element.fromAddress)) {
          fedexAddresses.push(element.fromAddress);
        }
      });

      const newData: IPickupData = {
        ups: formatData(upsAddresses, ups),
        fedex: formatData(fedexAddresses, fedex),
      };

      await dispatch(setStatus(ApiLoadingStatus.SUCCESS));

      if (!error) {
        dispatch(setData({ data: newData, meta }));
      }
    }
  };
};

export const fetchShipmentsWithPickupThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID },
      pickupSlice,
      tableSlice: { numberOfRowsSelected },
    } = getState() as RootState;

    const {
      offset,
      filters: { search, fromDate, toDate },
      sort,
    } = pickupSlice.viewPickupQuery;
    // fromDate should always be at 00:00:00 timestamp
    const adjustedFromDate = `${fromDate.split("T")[0]}T00:00:00.000Z`;
    // toDate should always be at 23:59:59 timestamp
    const adjustedToDate = `${toDate.split("T")[0]}T23:59:59.000Z`;
    if (salesAccountUUID) {
      const { data, error } = await fetchPickupShipments(salesAccountUUID, {
        offset,
        filters: {
          statuses: ShipmentStatusCategories.COMPLETE,
          ...(search && { search }),
          ...(fromDate && { fromDate: adjustedFromDate }),
          ...(toDate && { toDate: adjustedToDate }),
        },
        sort,
        limit: numberOfRowsSelected.value,
      });

      await dispatch(setStatus(ApiLoadingStatus.SUCCESS));
      if (!error) {
        dispatch(setShipmentsWithPickup(data));
      }
    }
  };
};
