import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import {
  fetchIntegrations,
  fetchActiveEcartIntegrations,
  createEcartIntegration,
  fetchIntegrationBySlug,
  createEbayIntergration,
  createShipstationIntergration,
} from "services/api/integrations";
import { FetchIntegrationsResponseDto } from "services/api/integrations/dtos/fetch-integrations.response.dto";
import { AppThunk, RootState } from "store";
import { IntegrationsSliceInitialState } from "./IntegrationsSliceInitialState.const";
import {
  CreateEbayIntegrationRequestDto,
  CreateShipstationIntegrationRequestDto,
} from "services/api/integrations/dtos/create-integration.request.dto";

export const INTEGRATIONS_SLICE_NAME = "integrationsSlice";

export const integrationsSlice = createSlice({
  name: INTEGRATIONS_SLICE_NAME,
  initialState: IntegrationsSliceInitialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<FetchIntegrationsResponseDto["data"]>
    ) => {
      state.data = action.payload;
    },
    setLoadingStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
    setProcessIntegration: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.processIntegration = action.payload;
    },
    setActiveEcartIntegrations: (state, action: PayloadAction<any>) => {
      state.activeEcartIntegrations = action.payload;
    },
    setIntegration: (state, action: PayloadAction<any>) => {
      state.integration = action.payload;
    },
    setShowAddStoreUI: (state, action: PayloadAction<boolean>) => {
      state.showAddStoreUI = action.payload;
    },
    setAccessCode: (state, action: PayloadAction<string>) => {
      state.accessCode = action.payload;
    },
    setStoreName: (state, action: PayloadAction<string>) => {
      state.storeName = action.payload;
    },
    setApiKey: (state, action: PayloadAction<string>) => {
      state.apiKey = action.payload;
    },
    setApiSecret: (state, action: PayloadAction<string>) => {
      state.apiSecret = action.payload;
    },
  },
});

export const {
  setData,
  setLoadingStatus,
  setActiveEcartIntegrations,
  setIntegration,
  setShowAddStoreUI,
  setAccessCode,
  setStoreName,
  setProcessIntegration,
  setApiKey,
  setApiSecret,
} = integrationsSlice.actions;

export const clearIntegrationStateThunk = () => {
  return async (dispatch) => {
    dispatch(setIntegration(null));
    dispatch(setShowAddStoreUI(false));
    dispatch(setAccessCode(""));
    dispatch(setStoreName(""));
    dispatch(setApiKey(""));
    dispatch(setApiSecret(""));
  };
};

export const fetchIntegrationSlugThunk = (
  slug: string | string[]
): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;
    if (typeof slug === "string") {
      try {
        const { data, error } = await fetchIntegrationBySlug(
          salesAccountUUID,
          slug
        );
        if (data) {
          dispatch(setIntegration(data));
        }

        if (error) {
          console.log("error", error);
        }
      } catch (e) {}
    }
  };
};

export const fetchIntegrationsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    dispatch(setLoadingStatus(ApiLoadingStatus.LOADING));

    try {
      const { data, error } = await fetchIntegrations(salesAccountUUID);

      if (data) {
        dispatch(setLoadingStatus(ApiLoadingStatus.SUCCESS));
        dispatch(setData(data));
      }

      if (error) {
        dispatch(setLoadingStatus(ApiLoadingStatus.FAILED));
      }
    } catch (e) {
      dispatch(setLoadingStatus(ApiLoadingStatus.FAILED));
    }
  };
};

export const createEcartIntegrationsThunk = (values: any): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    try {
      const { data, error } = await createEcartIntegration(
        salesAccountUUID,
        values
      );
      if (data) {
        console.log("data", data);
      }

      if (error) {
        console.log("error", error);
      }
    } catch (e) {}
  };
};

export const fetchActiveEcartIntegrationsThunk = () => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;
    try {
      const { data, error } = await fetchActiveEcartIntegrations(
        salesAccountUUID
      );
      if (data) {
        dispatch(setActiveEcartIntegrations(data));
      }

      if (error) {
        console.log("error", error);
      }
    } catch (e) {}
  };
};

export const createEbayIntegrationThunk = (
  values: CreateEbayIntegrationRequestDto
): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;
    dispatch(setProcessIntegration(ApiLoadingStatus.LOADING));
    try {
      const { data, error } = await createEbayIntergration(
        salesAccountUUID,
        values
      );
      if (data) {
        dispatch(fetchIntegrationSlugThunk("ebay"));
        dispatch(setProcessIntegration(ApiLoadingStatus.SUCCESS));
        dispatch(setShowAddStoreUI(false));
        dispatch(setAccessCode(""));
        dispatch(setStoreName(""));
      }

      if (error) {
        dispatch(setProcessIntegration(ApiLoadingStatus.FAILED));
      }
    } catch (e) {
      dispatch(setProcessIntegration(ApiLoadingStatus.FAILED));
    }
  };
};

export const createShipStationIntegrationThunk = (
  values: CreateShipstationIntegrationRequestDto
): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;
    dispatch(setProcessIntegration(ApiLoadingStatus.LOADING));
    try {
      const { data, error } = await createShipstationIntergration(
        salesAccountUUID,
        values
      );
      if (data) {
        dispatch(fetchIntegrationSlugThunk("shipstation"));
        dispatch(setProcessIntegration(ApiLoadingStatus.SUCCESS));
        dispatch(setShowAddStoreUI(false));
        dispatch(setApiKey(""));
        dispatch(setApiSecret(""));
        dispatch(setStoreName(""));
      }

      if (error) {
        dispatch(setProcessIntegration(ApiLoadingStatus.FAILED));
      }
    } catch (e) {
      dispatch(setProcessIntegration(ApiLoadingStatus.FAILED));
    }
  };
};
