import { AppThunk, RootState } from "store";
import { createPickup, cancelPickup } from "services/api/shipments";
import { closeModal } from "store/modalSlice";
import dayjs from "dayjs";
import {
  fetchEligiblePickUpShipmentsThunk,
  fetchShipmentsWithPickupThunk,
} from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";
import { fetchShipmentsThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { PickupShipmentRequestDto } from "services/api/shipments/dtos/pickup-shipment.request.dto";
import toast from "react-hot-toast";
import { setCreateStatus } from "store/pickupSlice/index.slice";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";

export const createPickUpRequestThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setCreateStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID, financialCompany },
      pickupSlice: {
        pickupDate,
        pickupFromTime,
        pickupToTime,
        data: pickupData,
        selectedPickUpID,
        pickupInstructions,
      },
    } = getState() as RootState;
    const shipmentIDs: number[] = [];
    const cancelingRequiredShipmentIDs: number[] = [];
    let carrier = "";
    let body: PickupShipmentRequestDto;
    let cancelBody: PickupShipmentRequestDto;
    pickupData.fedex.forEach((element) => {
      if (!element.hide && element.id === selectedPickUpID) {
        carrier = "fedex";
        element.shipments.forEach((shipment) => {
          if (shipment.selected) {
            shipmentIDs.push(parseInt(shipment.shipmentId.toString()));
            if (shipment.prnNumber) {
              cancelingRequiredShipmentIDs.push(
                parseInt(shipment.shipmentId.toString())
              );
            }
          }
        });
      }
    });
    pickupData.ups.forEach((element) => {
      if (!element.hide && element.id === selectedPickUpID) {
        carrier = "ups";
        element.shipments.forEach((shipment) => {
          if (shipment.selected) {
            shipmentIDs.push(parseInt(shipment.shipmentId.toString()));
            if (shipment.prnNumber) {
              cancelingRequiredShipmentIDs.push(
                parseInt(shipment.shipmentId.toString())
              );
            }
          }
        });
      }
    });
    const newDate = pickupDate.split("T")[0];
    const fromTime = pickupFromTime.split("T")[1];
    const toTime = pickupToTime.split("T")[1];

    const fromDate = dayjs(new Date(newDate + "T" + fromTime)).format(
      "HH:mm:ss"
    );
    const toDate = dayjs(new Date(newDate + "T" + toTime)).format("HH:mm:ss");

    const puDate = dayjs(new Date(pickupDate)).format("YYYY-MM-DD");
    if (carrier === "fedex") {
      body = {
        FinancialCompany: financialCompany,
        Pickups: {
          shipmentIDs: shipmentIDs,
          PickupDate: puDate,
          PickupFrom: fromDate,
          PickupTo: toDate,
          SpecialInstructions: pickupInstructions,
        },
      };
      cancelBody = {
        FinancialCompany: financialCompany,
        Pickups: {
          shipmentIDs: shipmentIDs,
        },
      };
    } else {
      body = {
        shipmentIDs: shipmentIDs,
        PickupDate: puDate,
        PickupFrom: fromDate,
        PickupTo: toDate,
        SpecialInstructions: pickupInstructions,
      };
      cancelBody = {
        shipmentIDs: cancelingRequiredShipmentIDs,
      };
    }

    if (cancelingRequiredShipmentIDs.length) {
      await cancelPickup(salesAccountUUID, cancelBody);
    }
    const { data }: any = await createPickup(salesAccountUUID, body);
    if (!data.error) {
      await dispatch(setCreateStatus(ApiLoadingStatus.SUCCESS));
      dispatch(fetchEligiblePickUpShipmentsThunk());
      dispatch(fetchShipmentsWithPickupThunk());
      dispatch(fetchShipmentsThunk());
      dispatch(closeModal({ modalName: "pickUpRequestModal" }));
      toast.success("Pickup created");
    } else {
      dispatch(closeModal({ modalName: "pickUpRequestModal" }));
      toast.error(data.message.message);
    }
  };
};
