import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShipmentStatusCategories } from "constants/shipment-statuses.config";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { ShipmentStatusCategoryStrings } from "enum/shipment-status-category-string.enum";
import { IShipment } from "models/shipment/shipment.model";
import { FetchShipmentsResponseDto } from "services/api/shipments/dtos/fetch-shipments.response.dto";
import { IShipmentsSlice } from "./ShipmentsSlice.interface";
import {
  isDataUpdated,
  ShipmentsSliceActions,
} from "./ShipmentsSliceActions.enum";
import { ShipmentsInitialState } from "./ShipmentsSliceInitialState.const";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";
import { SortDirection } from "interfaces/api.interface";

export const SHIPMENTS_SLICE_NAME = "shipmentsSlice";

export const shipmentsSlice = createSlice({
  name: SHIPMENTS_SLICE_NAME,
  initialState: ShipmentsInitialState,
  reducers: {
    [ShipmentsSliceActions.SET_DATA]: (
      state,
      action: PayloadAction<FetchShipmentsResponseDto>
    ) => {
      state.items = action.payload.data;

      // sort items by index

      state.meta = action.payload.meta;
    },
    setSelectedShipmentIds: (
      state,
      action: PayloadAction<IShipmentsSlice["selectedShipmentIds"]>
    ) => {
      state.selectedShipmentIds = action.payload;
    },
    setCurrentShipmentLoadingStatus: (
      state,
      action: PayloadAction<ApiLoadingStatus>
    ) => {
      state.currentShipmentLoadingStatus = action.payload;
    },
    setQuerySearch: (
      state,
      action: PayloadAction<IShipmentsSlice["query"]["filters"]["search"]>
    ) => {
      state.query.filters.search = action.payload;
      state.query.offset = 0;
    },
    setQueryBulkShipment: (
      state,
      action: PayloadAction<IShipmentsSlice["query"]["filters"]["bulkShipment"]>
    ) => {
      state.query.filters.bulkShipment = action.payload;
      state.query.offset = 0;
    },
    setQueryLabelJob: (
      state,
      action: PayloadAction<IShipmentsSlice["query"]["filters"]["labelJob"]>
    ) => {
      state.query.filters.labelJob = action.payload;
      state.query.offset = 0;
    },
    setQueryFromDate: (
      state,
      action: PayloadAction<IShipmentsSlice["query"]["filters"]["fromDate"]>
    ) => {
      state.query.filters.fromDate = action.payload;
    },
    setQueryToDate: (
      state,
      action: PayloadAction<IShipmentsSlice["query"]["filters"]["toDate"]>
    ) => {
      state.query.filters.toDate = action.payload;
    },
    setQuerySort: (
      state,
      action: PayloadAction<IShipmentsSlice["query"]["sort"]>
    ) => {
      state.query.sort = action.payload;
    },
    setQueryFromAddressCountryIso: (
      state,
      action: PayloadAction<
        IShipmentsSlice["query"]["filters"]["fromAddressCountryIso"]
      >
    ) => {
      state.query.filters.fromAddressCountryIso = action.payload;
      state.query.offset = 0;
    },
    setQueryToAddressCountryIso: (
      state,
      action: PayloadAction<
        IShipmentsSlice["query"]["filters"]["toAddressCountryIso"]
      >
    ) => {
      state.query.filters.toAddressCountryIso = action.payload;
      state.query.offset = 0;
    },
    setQueryStatusFilter: (
      state,
      action: PayloadAction<ShipmentStatusCategoryStrings>
    ) => {
      state.query.filters.statuses = ShipmentStatusCategories[action.payload];

      state.items = [];

      state.selectedShipmentIds = [];

      state.query.filters.search = "";

      state.query.sort = {
        createdDate: SortDirection.DESC,
      };

      state.query.filters.trackingStatus = null;

      state.meta.shipmentIds = [];

      state.loadingStatus = ApiLoadingStatus.LOADING;
    },
    setQueryToDefault: (state) => {
      state.query = ShipmentsInitialState.query;
    },
    [ShipmentsSliceActions.SET_QUERY_OFFSET]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.query.offset = action.payload;
    },
    setCurrentShipment: (state, action: PayloadAction<IShipment>) => {
      state.currentShipment = action.payload;
      // sort packages by index

      state.currentShipment.packages =
        state.currentShipment?.packages?.sort((a, b) => a.index - b.index) ||
        [];

      state.currentShipmentLoadingStatus = "SUCCESS";
    },
    setShowMore: (state, action: PayloadAction<boolean>) => {
      state.showMore = action.payload;
    },
    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
    setReportShipmentFrom: (state, action: PayloadAction<string>) => {
      state.reportShipmentFrom = action.payload;
    },
    setReportShipmentTo: (state, action: PayloadAction<string>) => {
      state.reportShipmentTo = action.payload;
    },
    setTrackingStatuses: (state, action: PayloadAction<string[]>) => {
      state.listOfTrackingStatuses = action.payload;
    },
    setAllIntegrationStores: (
      state,
      action: PayloadAction<{ id: string; identifier: string }[]>
    ) => {
      state.allIntegrationStores = action.payload;
    },
    setQueryTrackingStatus: (
      state,
      action: PayloadAction<
        IShipmentsSlice["query"]["filters"]["trackingStatus"]
      >
    ) => {
      state.query.filters.trackingStatus = action.payload;
    },
    setQueryIntegrationStoreId: (
      state,
      action: PayloadAction<
        IShipmentsSlice["query"]["filters"]["integrationStoreId"]
      >
    ) => {
      state.query.filters.integrationStoreId = action.payload;
    },
    setDateRangeDays: (
      state,
      action: PayloadAction<IShipmentsSlice["dateRangeDays"]>
    ) => {
      state.dateRangeDays = action.payload;
    },
  },
  extraReducers: (builder) => {
    // If user has selected one or more shipments, and then edits a shipment which results in a refetch of the data, the deleted shipment should be removed from the selection.
    builder.addMatcher(isDataUpdated, (state) => {
      state.selectedShipmentIds = state.selectedShipmentIds.filter((id) => {
        return state.meta?.shipmentIds?.includes(id);
      });
    });
    builder.addMatcher(
      isModalClosed,
      (state, action: { payload: { modalName: modals } }) => {
        if (action.payload.modalName === "viewShipment") {
          state.showMore = false;
        }
        if (action.payload.modalName === "shipmentReport") {
          state.reportShipmentFrom = new Date().toISOString();
          state.reportShipmentTo = new Date().toISOString();
        }
      }
    );
  },
});
export const {
  setData,
  setStatus,
  setQueryFromAddressCountryIso,
  setQueryToAddressCountryIso,
  setQueryTrackingStatus,
  setQueryFromDate,
  setQueryToDate,
  setQueryLabelJob,
  setQuerySort,
  setQueryStatusFilter,
  setQueryBulkShipment,
  setQueryOffset,
  setQuerySearch,
  setCurrentShipmentLoadingStatus,
  setSelectedShipmentIds,
  setCurrentShipment,
  setShowMore,
  setQueryToDefault,
  setReportShipmentFrom,
  setReportShipmentTo,
  setTrackingStatuses,
  setAllIntegrationStores,
  setQueryIntegrationStoreId,
  setDateRangeDays,
} = shipmentsSlice.actions;
