import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import {
  BulkUploadColumnName,
  BulkUploadItemFieldNames,
  BulkUploadPackageFieldNames,
  PackagesExtra,
} from "enum/bulk-upload-column-names.enum";
import { IShipmentProfile } from "models/shipmentProfile/shipmentProfile.model";
import { ICreateOrUpdateBulkShipmentProfileSlice } from "./createOrUpdateBulkShipmentProfileSlice.interface";

export type IMappingColumn = {
  required: boolean;
  label: string;
};

// Rename this
export const MappingColumns: {
  [key in
    | PackagesExtra
    | BulkUploadColumnName
    | BulkUploadPackageFieldNames
    | BulkUploadItemFieldNames]: IMappingColumn;
} = {
  [BulkUploadColumnName.warehouseName]: {
    required: false,
    label: "Warehouse name",
  },
  [BulkUploadColumnName.customerReference]: {
    required: true,
    label: "Customer reference",
  },
  [BulkUploadColumnName.customerReference2]: {
    required: false,
    label: "Customer reference 2",
  },
  [BulkUploadColumnName.iossNumber]: {
    required: false,
    label: "IOSS number",
  },
  [BulkUploadColumnName.invoiceDate]: {
    required: false,
    label: "Invoice Date",
  },
  [BulkUploadColumnName.invoiceNumber]: {
    required: false,
    label: "Invoice Number",
  },
  [BulkUploadColumnName.orderNumber]: {
    required: false,
    label: "Order Number",
  },
  [BulkUploadColumnName.reasonForExport]: {
    required: false,
    label: "Reason for import / export",
  },
  [BulkUploadColumnName.toAddressFirstName]: {
    required: false,
    label: "To address first name",
  },
  [BulkUploadColumnName.toAddressLastName]: {
    required: true,
    label: "To address last name",
  },
  [BulkUploadColumnName.toAddressPhone]: {
    required: false,
    label: "To address phone",
  },
  [BulkUploadColumnName.toAddressEmail]: {
    required: false,
    label: "To address email",
  },
  [BulkUploadColumnName.toAddressCompany]: {
    required: false,
    label: "To address company",
  },
  [BulkUploadColumnName.toAddressStreet1]: {
    required: true,
    label: "To address street 1",
  },
  [BulkUploadColumnName.toAddressStreet2]: {
    required: false,
    label: "To address street 2",
  },
  [BulkUploadColumnName.toAddressStreet3]: {
    required: false,
    label: "To address street 3",
  },
  [BulkUploadColumnName.toAddressCity]: {
    required: true,
    label: "To address city",
  },
  [BulkUploadColumnName.toAddressCountyState]: {
    required: false,
    label: "To address state",
  },
  [BulkUploadColumnName.toAddressZip]: {
    required: true,
    label: "To address zip",
  },
  [BulkUploadColumnName.toAddressCountryIso]: {
    required: true,
    label: "To address country",
  },
  [BulkUploadColumnName.toAddressVatNumber]: {
    required: false,
    label: "To address VAT number",
  },
  [BulkUploadColumnName.toAddressEoriNumber]: {
    required: false,
    label: "To address EORI number",
  },
  numberOfPackages: {
    required: false,
    label: "Number of Packages",
  },
  [BulkUploadPackageFieldNames.packageHeight]: {
    required: false,
    label: "Package height",
  },
  [BulkUploadPackageFieldNames.packageLength]: {
    required: false,
    label: "Package length",
  },
  [BulkUploadPackageFieldNames.packageWidth]: {
    required: false,
    label: "Package width",
  },
  [BulkUploadPackageFieldNames.packageType]: {
    required: false,
    label: "Package type",
  },
  [BulkUploadPackageFieldNames.packageWeight]: {
    required: false,
    label: "Package weight",
  },
  [BulkUploadItemFieldNames.itemSku]: {
    required: false,
    label: "Item SKU",
  },
  [BulkUploadItemFieldNames.itemValue]: {
    required: false,
    label: "Item Value",
  },
  [BulkUploadItemFieldNames.itemDescription]: {
    required: false,
    label: "Item description",
  },
  [BulkUploadItemFieldNames.itemHsCode]: {
    required: false,
    label: "Item HS code",
  },
  [BulkUploadItemFieldNames.itemOriginCountryIso]: {
    required: false,
    label: "Item origin country",
  },
  [BulkUploadItemFieldNames.itemQuantity]: {
    required: false,
    label: "Item quantity",
  },
  [BulkUploadItemFieldNames.itemValueCurrency]: {
    required: false,
    label: "Item value currency",
  },
  [BulkUploadItemFieldNames.itemWeight]: {
    required: false,
    label: "Item weight",
  },
  [BulkUploadItemFieldNames.manufacturerDetails]: {
    required: false,
    label: "Manufacturer details",
  },
  [BulkUploadItemFieldNames.shippingMethod]: {
    required: false,
    label: "Shipping Method",
  },
  [BulkUploadItemFieldNames.custom]: {
    required: false,
    label: "Custom",
  },
  [BulkUploadColumnName.fromAddressFirstName]: {
    required: false,
    label: "From address first name",
  },
  [BulkUploadColumnName.fromAddressLastName]: {
    required: false,
    label: "From address last name",
  },
  [BulkUploadColumnName.fromAddressPhone]: {
    required: false,
    label: "From address phone",
  },
  [BulkUploadColumnName.fromAddressEmail]: {
    required: false,
    label: "From address email",
  },
  [BulkUploadColumnName.fromAddressCompany]: {
    required: false,
    label: "From address company",
  },
  [BulkUploadColumnName.fromAddressStreet1]: {
    required: false,
    label: "From address street 1",
  },
  [BulkUploadColumnName.fromAddressStreet2]: {
    required: false,
    label: "From address street 2",
  },
  [BulkUploadColumnName.fromAddressStreet3]: {
    required: false,
    label: "From address street 3",
  },
  [BulkUploadColumnName.fromAddressCity]: {
    required: false,
    label: "From address city",
  },
  [BulkUploadColumnName.fromAddressCountyState]: {
    required: false,
    label: "From address county / state",
  },
  [BulkUploadColumnName.fromAddressZip]: {
    required: false,
    label: "From address zip",
  },
  [BulkUploadColumnName.fromAddressCountryIso]: {
    required: false,
    label: "From address country",
  },
  [BulkUploadColumnName.fromAddressVatNumber]: {
    required: false,
    label: "From address VAT number",
  },
  [BulkUploadColumnName.fromAddressEoriNumber]: {
    required: false,
    label: "From address EORI number",
  },
  [BulkUploadColumnName.dangerousGoods]: {
    required: false,
    label: "Dangerous goods",
  },
};

export const generateDefaultMappings = (): IShipmentProfile["mappings"] => {
  const data = {} as IShipmentProfile["mappings"];

  Object.keys(MappingColumns).map((key) => {
    data[key] = null;
  });

  return data;
};

export const CreateOrUpdateBulkShipmentProfileSliceInitialState: ICreateOrUpdateBulkShipmentProfileSlice =
  {
    title: "",
    fileContainsHeaderRow: true,
    submittingStatus: ApiLoadingStatus.IDLE,
    loadingStatus: ApiLoadingStatus.IDLE,
    editingProfileUUID: null,
    editingProfile: null,
    data: {
      data: [],
      errors: [],
      meta: {
        fields: [],
        delimiter: "",
        linebreak: "",
        aborted: false,
        truncated: false,
        cursor: 0,
      },
    },
    mappings: generateDefaultMappings(),
    profileToDelete: null,
  };
