import {
  BulkUploadColumnName,
  BulkUploadItemFieldNames,
  BulkUploadPackageFieldNames,
  PackagesExtra,
} from "enum/bulk-upload-column-names.enum";
import { RuleOperators } from "enum/rule-operators.enum";

export type RuleConditions =
  | BulkUploadColumnName
  | BulkUploadPackageFieldNames
  | BulkUploadItemFieldNames
  | PackagesExtra;

export type RuleCondition = {
  fact: RuleConditions | null;
  operator: RuleOperators | null;
  value: string | number | string[];
};

export const Operators: { name: string; value: RuleOperators }[] = [
  {
    name: "Equal to",
    value: RuleOperators.equal,
  },
  {
    name: "Not equal to",
    value: RuleOperators.notEqual,
  },
  {
    name: "Contains",
    value: RuleOperators.contains,
  },
  {
    name: "Does not contain",
    value: RuleOperators.doesNotContain,
  },
  {
    name: "Lower than",
    value: RuleOperators.lessThan,
  },
  {
    name: "Greater than",
    value: RuleOperators.greaterThan,
  },
  {
    name: "Lower than or equal",
    value: RuleOperators.lessThanInclusive,
  },
  {
    name: "Greater than or equal",
    value: RuleOperators.greaterThanInclusive,
  },
  {
    name: "In",
    value: RuleOperators.in,
  },
  {
    name: "Not in",
    value: RuleOperators.notIn,
  },
];

export enum RuleComponentNames {
  "INPUT" = "INPUT",
  "NUMBER" = "NUMBER",
  "COUNTRY_SELECT" = "COUNTRY_SELECT",
  "PACKAGE_TYPE_SELECT" = "PACKAGE_TYPE_SELECT",
}

const DEFAULT_NUMERIC_OPERATORS = [
  RuleOperators.equal,
  RuleOperators.notEqual,
  RuleOperators.greaterThan,
  RuleOperators.greaterThanInclusive,
  RuleOperators.lessThan,
  RuleOperators.lessThanInclusive,
];

const DEFAULT_STRING_OPERATORS = [
  RuleOperators.equal,
  RuleOperators.notEqual,
  RuleOperators.contains,
  RuleOperators.doesNotContain,
  RuleOperators.in,
  RuleOperators.notIn,
];

export type IRuleCondition = {
  name: string;
  value: RuleConditions;
  component: RuleComponentNames;
  operators: RuleOperators[];
};

export const Conditions = ({
  dimensionUnit,
  weightUnit,
}: {
  dimensionUnit: string;
  weightUnit: string;
}): IRuleCondition[] => {
  return [
    {
      name: "To address country",
      operators: [RuleOperators.equal],
      component: RuleComponentNames.COUNTRY_SELECT,
      value: BulkUploadColumnName.toAddressCountryIso,
    },
    {
      name: "To address city",
      operators: DEFAULT_STRING_OPERATORS,
      component: RuleComponentNames.INPUT,
      value: BulkUploadColumnName.toAddressCity,
    },
    {
      name: "To address county / state",
      operators: DEFAULT_STRING_OPERATORS,
      component: RuleComponentNames.INPUT,
      value: BulkUploadColumnName.toAddressCountyState,
    },
    {
      name: "To address zip",
      operators: DEFAULT_STRING_OPERATORS,
      component: RuleComponentNames.INPUT,
      value: BulkUploadColumnName.toAddressZip,
    },
    {
      name: "From address country",
      operators: [RuleOperators.equal],
      component: RuleComponentNames.COUNTRY_SELECT,
      value: BulkUploadColumnName.fromAddressCountryIso,
    },
    {
      name: "From address city",
      operators: DEFAULT_STRING_OPERATORS,
      component: RuleComponentNames.INPUT,
      value: BulkUploadColumnName.fromAddressCity,
    },
    {
      name: "From address county / state",
      operators: DEFAULT_STRING_OPERATORS,
      component: RuleComponentNames.INPUT,
      value: BulkUploadColumnName.fromAddressCountyState,
    },
    {
      name: "From address zip",
      operators: DEFAULT_STRING_OPERATORS,
      component: RuleComponentNames.INPUT,
      value: BulkUploadColumnName.fromAddressZip,
    },
    {
      name: `Package width (${dimensionUnit})`,
      operators: DEFAULT_NUMERIC_OPERATORS,
      component: RuleComponentNames.NUMBER,
      value: BulkUploadPackageFieldNames.packageWidth,
    },
    {
      name: `Package weight (${weightUnit})`,
      operators: DEFAULT_NUMERIC_OPERATORS,
      component: RuleComponentNames.NUMBER,
      value: BulkUploadPackageFieldNames.packageWeight,
    },
    {
      name: `Package length (${dimensionUnit})`,
      operators: DEFAULT_NUMERIC_OPERATORS,
      component: RuleComponentNames.NUMBER,
      value: BulkUploadPackageFieldNames.packageLength,
    },
    {
      name: `Package height (${dimensionUnit})`,
      operators: DEFAULT_NUMERIC_OPERATORS,
      component: RuleComponentNames.NUMBER,
      value: BulkUploadPackageFieldNames.packageHeight,
    },
    {
      name: "Package type",
      operators: [RuleOperators.equal],
      component: RuleComponentNames.PACKAGE_TYPE_SELECT,
      value: BulkUploadPackageFieldNames.packageType,
    },
    {
      name: "Number of packages",
      operators: DEFAULT_NUMERIC_OPERATORS,
      component: RuleComponentNames.NUMBER,
      value: PackagesExtra.NumberOfPackages,
    },
    {
      name: "Item SKU",
      operators: DEFAULT_STRING_OPERATORS,
      component: RuleComponentNames.INPUT,
      value: BulkUploadItemFieldNames.itemSku,
    },
    {
      name: "Item Value",
      operators: DEFAULT_NUMERIC_OPERATORS,
      component: RuleComponentNames.NUMBER,
      value: BulkUploadItemFieldNames.itemValue,
    },
    {
      name: "Item description",
      operators: DEFAULT_STRING_OPERATORS,
      component: RuleComponentNames.INPUT,
      value: BulkUploadItemFieldNames.itemDescription,
    },
    {
      name: "Item quantity",
      operators: DEFAULT_NUMERIC_OPERATORS,
      component: RuleComponentNames.NUMBER,
      value: BulkUploadItemFieldNames.itemQuantity,
    },
    {
      name: `Item weight (${weightUnit})`,
      operators: DEFAULT_NUMERIC_OPERATORS,
      component: RuleComponentNames.NUMBER,
      value: BulkUploadItemFieldNames.itemWeight,
    },
    {
      name: "Shipping method",
      operators: DEFAULT_STRING_OPERATORS,
      component: RuleComponentNames.INPUT,
      value: BulkUploadItemFieldNames.shippingMethod,
    },
    {
      name: "Custom",
      operators: [...DEFAULT_NUMERIC_OPERATORS, ...DEFAULT_STRING_OPERATORS],
      component: RuleComponentNames.INPUT,
      value: BulkUploadItemFieldNames.custom,
    },
    {
      name: "Dangerous goods",
      operators: [...DEFAULT_STRING_OPERATORS],
      component: RuleComponentNames.INPUT,
      value: BulkUploadColumnName.dangerousGoods,
    },
  ];
};

export interface IRule {
  id: string;
  uuid: string;
  name: string;
  shippingWhitelistId: string;
  virtualWarehouseId: number;
  whitelist: {
    status: "A" | "D";
  };
  carrierId: string;
  enabled: boolean;
  priority: number;
  conditions: RuleCondition[];
  description: string;
}
