import { FetchSettingsResponseDto } from "./dtos/fetch-settings.response.dto";
import { fetchSingle } from "services/get";
import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { patch } from "services/patch";

export const fetchSettings = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<FetchSettingsResponseDto> => {
  return await fetchSingle(
    customerApiBase + "/" + salesAccountUUID + apiPaths.settings
  );
};

export const updateSettings = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: string
): Promise<FetchSettingsResponseDto> => {
  return await patch(
    customerApiBase + "/" + salesAccountUUID + apiPaths.settings + "/weightUnits" ,
    values
  );
}