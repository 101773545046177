import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { IAddress } from "models/address/address.model";
import {
  ICreateShipmentSlice,
  SetDropOffPayload,
  CreateShipmentAddress,
  CreateAddress,
  CreateShipmentContents,
  CreateShipmentAddressType,
  FoundDropOffLocationsDistance,
  FoundDropOffLocations,
} from "./createShipmentSlice.types";
import {
  DEFAULT_FROM_COUNTRY_ISO_CODE,
  DEFAULT_PACKAGE,
  GA_DEFAULT_FROM_COUNTRY_ISO_CODE,
} from "constants/misc";
import {
  isModalClosed,
  isModalOpened,
} from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";
import {
  IShipmentPackage,
  IShipmentPackageGrouped,
} from "models/shipmentPackage/shipmentPackage.model";
import {
  CreateShipmentSliceActions,
  isCountryChanged,
} from "./CreateShipmentSlice.actions.enum";
import {
  CreateShipmentInitialState,
  DEFAULT_ITEM_CONTENT,
  DEFAULT_ITEM_CONTENT_ERRORS,
  DEFAULT_ITEM_CONTENT_TOUCHED,
  DEFAULT_PACKAGE_ERRORS,
  DEFAULT_PACKAGE_TOUCHED,
  DEFAULT_PICKUP_VALUES,
  DEFAULT_DROPOFF_VALUES,
} from "./CreateShipmentSliceDefaults.const";
import { isZipRequired } from "utils/zip/isZipRequired.util";
import { findIndex, findLastIndex } from "lodash";
import { ISalesAccountDefaults } from "models/salesAccount/salesAccount.model";
import { fetchShipmentDropOffLocations } from "services/api/shipments";
import { AppThunk } from "store";

/*
Notes:

1. itd-staff account types will not have default settings loaded on the front end. Therefore, you must use the ? operator to handle null default settings.

*/

export const CREATE_SHIPMENT_SLICE_NAME = "createShipmentSlice";

export const createShipmentSlice = createSlice({
  name: CREATE_SHIPMENT_SLICE_NAME,
  initialState: CreateShipmentInitialState,
  reducers: {
    [CreateShipmentSliceActions.SET_INSURANCE_CURRENCY]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.insurance.insuranceAmountCurrency = action.payload;
    },

    [CreateShipmentSliceActions.SET_DEFAULT_FROM_ISO_GA]: (state) => {
      state.values.sender.countryIsoCode = GA_DEFAULT_FROM_COUNTRY_ISO_CODE;
      state.values.receiver.countryIsoCode = GA_DEFAULT_FROM_COUNTRY_ISO_CODE;
    },
    [CreateShipmentSliceActions.SET_INSURANCE_AMOUNT]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.insurance.insuranceAmount = action.payload;
    },

    [CreateShipmentSliceActions.SET_USE_INSURANCE]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      if (action.payload === false) {
        state.insurance.insuranceAmount = 0;
      }
      state.insurance.useInsurance = action.payload;
    },

    [CreateShipmentSliceActions.SET_DISCLAIMER_CHECKED]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDisclaimerAccepted = action.payload;
    },

    [CreateShipmentSliceActions.SET_PICKUP_INSTRUCTIONS]: (
      state,
      action: PayloadAction<
        ICreateShipmentSlice["pickUpObject"]["pickupInstructions"]
      >
    ) => {
      state.pickUpObject.pickupInstructions = action.payload;
    },
    [CreateShipmentSliceActions.SET_PICKUP_TO_TIME]: (
      state,
      action: PayloadAction<string>
    ) => {
      // dsv wants date in local time zone
      state.pickUpObject.pickupToTime = action.payload;
    },
    [CreateShipmentSliceActions.SET_PICKUP_FROM_TIME]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.pickUpObject.pickupFromTime = action.payload;
    },
    [CreateShipmentSliceActions.SET_PICKUP]: (
      state,
      action: PayloadAction<
        ICreateShipmentSlice["pickUpObject"]["shouldPickUp"]
      >
    ) => {
      state.pickUpObject.shouldPickUp = action.payload;
      if (action.payload && state.dropOffObject.shouldDropOff) {
        state.dropOffObject.shouldDropOff = false;
      }
    },
    [CreateShipmentSliceActions.SET_DROPOFF]: (
      state,
      action: PayloadAction<SetDropOffPayload>
    ) => {
      const { shouldDropOff } = action.payload;
      state.dropOffObject.shouldDropOff = shouldDropOff;

      if (shouldDropOff && state.pickUpObject.shouldPickUp) {
        state.pickUpObject.shouldPickUp = false;
      }
    },
    [CreateShipmentSliceActions.SET_SELECTED_LOCATION]: (
      state,
      action: PayloadAction<FoundDropOffLocations>
    ) => {
      state.dropOffObject.selectedDropOffLocation = action.payload;
    },
    [CreateShipmentSliceActions.SET_DROP_OFF_LOCATIONS]: (
      state,
      action: PayloadAction<FoundDropOffLocationsDistance[]>
    ) => {
      state.dropOffObject.dropOffLocations = action.payload;
    },
    [CreateShipmentSliceActions.SET_SENDING_TO_RESIDENCE]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shippingToResidence = action.payload;
    },
    [CreateShipmentSliceActions.SET_PICKUP_DATE]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.pickUpObject.pickupDate = action.payload;
      const newDate = action.payload.split("T")[0];
      const fromTime = state.pickUpObject.pickupFromTime.split("T")[1];
      const toTime = state.pickUpObject.pickupToTime.split("T")[1];
      state.pickUpObject.pickupFromTime = newDate + "T" + fromTime;
      state.pickUpObject.pickupToTime = newDate + "T" + toTime;
    },
    [CreateShipmentSliceActions.SET_SALES_ACCOUNT_UUID]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["salesAccountUUID"]>
    ) => {
      state.salesAccountUUID = action.payload;
    },

    [CreateShipmentSliceActions.SET_ERRORS]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["errors"]>
    ) => {
      state.errors = action.payload;
    },

    [CreateShipmentSliceActions.SET_SUBMIT_COUNT]: (state) => {
      state.submitCount = state.submitCount + 1;
    },

    [CreateShipmentSliceActions.SET_PRINT_LATER]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["printLater"]>
    ) => {
      state.printLater = action.payload;
    },

    [CreateShipmentSliceActions.SET_CONVERTING_FROM_QUOTE_STATUS]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["convertingFromQuoteStatus"]>
    ) => {
      state.convertingFromQuoteStatus = action.payload;
    },

    [CreateShipmentSliceActions.SET_IS_CONVERTING_FROM_QUOTE]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["isConvertingFromQuote"]>
    ) => {
      state.isConvertingFromQuote = action.payload;
    },

    [CreateShipmentSliceActions.SET_VALUES]: (
      state,
      action: PayloadAction<
        Pick<
          ICreateShipmentSlice["values"],
          "receiver" | "sender" | "items" | "details" | "contents"
        >
      >
    ) => {
      state.values = {
        ...state.values,
        ...action.payload,
      };
    },

    [CreateShipmentSliceActions.SET_SENDER_SAVE_TO_ADDRESS_BOOK]: (
      state,
      action: PayloadAction<
        ICreateShipmentSlice["values"]["sender"]["saveToAddressBook"]
      >
    ) => {
      state.values.sender.saveToAddressBook = action.payload;
    },

    [CreateShipmentSliceActions.SET_RECIPIENT_SAVE_TO_ADDRESS_BOOK]: (
      state,
      action: PayloadAction<
        ICreateShipmentSlice["values"]["receiver"]["saveToAddressBook"]
      >
    ) => {
      state.values.receiver.saveToAddressBook = action.payload;
    },

    [CreateShipmentSliceActions.SET_EDITING_ID]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["editingShipmentID"]>
    ) => {
      state.editingShipmentID = action.payload;
    },

    [CreateShipmentSliceActions.SET_EDITING_UUID]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["editingShipmentUUID"]>
    ) => {
      state.editingShipmentUUID = action.payload;
    },

    [CreateShipmentSliceActions.SET_EDITING_JOB_NUMBER]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["editingShipmentJobNumber"]>
    ) => {
      state.editingShipmentJobNumber = action.payload;
    },

    [CreateShipmentSliceActions.SET_SELECTED_RATE]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["selectedRate"]>
    ) => {
      if (!action.payload) {
        // reset pick up and insurance if deselected a service
        state.pickUpObject = DEFAULT_PICKUP_VALUES;
        state.dropOffObject = DEFAULT_DROPOFF_VALUES;
        state.insurance = CreateShipmentInitialState.insurance;
      }
      state.selectedRate = action.payload;
    },

    [CreateShipmentSliceActions.SET_RATES_LOADING_STATUS]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["ratesLoadingStatus"]>
    ) => {
      state.ratesLoadingStatus = action.payload;
    },

    [CreateShipmentSliceActions.SET_SUBMITTING_STATUS]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["submittingStatus"]>
    ) => {
      state.submittingStatus = action.payload;
    },

    [CreateShipmentSliceActions.SET_SUBMITTING_ERROR_MESSAGE]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["submittingErrorMessage"]>
    ) => {
      state.submittingErrorMessage = action.payload;
    },

    [CreateShipmentSliceActions.SET_FORM_TYPE]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["type"]>
    ) => {
      state.type = action.payload;
    },

    [CreateShipmentSliceActions.SET_RATES_ERROR]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["ratesError"]>
    ) => {
      state.ratesError = action.payload;
    },

    [CreateShipmentSliceActions.SET_SENDER_SAVED_ADDRESS]: (
      state,
      action: PayloadAction<
        Omit<CreateShipmentAddress, "type" | "saveToAddressBook">
      >
    ) => {
      state.values.sender.id = action.payload.id;
      state.values.sender.firstName = action.payload.firstName;
      state.values.sender.lastName = action.payload.lastName;
      state.values.sender.addressLine1 = action.payload.addressLine1;
      state.values.sender.addressLine2 = action.payload.addressLine2;
      state.values.sender.addressLine3 = action.payload.addressLine3;
      state.values.sender.city = action.payload.city;
      state.values.sender.countyState = action.payload.countyState;
      state.values.sender.countryIsoCode = action.payload.countryIsoCode;
      state.values.sender.zip = action.payload.zip;
      state.values.sender.phone = action.payload.phone;
      state.values.sender.email = action.payload.email;
      state.values.sender.company = action.payload.company;
      state.values.sender.eoriNumber = action.payload.eoriNumber;
      state.values.sender.vatNumber = action.payload.vatNumber;
      state.values.sender.isSenderDefault = action.payload.isSenderDefault;
    },

    [CreateShipmentSliceActions.SET_DEEFAULT_FROM_ADDRESS]: (
      state,
      action: PayloadAction<
        Omit<CreateShipmentAddress, "type" | "saveToAddressBook">
      >
    ) => {
      state.values.sender.id = action.payload.id;
      state.values.sender.isSenderDefault = action.payload.isSenderDefault;
      state.values.sender.firstName = action.payload.firstName;
      state.values.sender.lastName = action.payload.lastName;
      state.values.sender.addressLine1 = action.payload.addressLine1;
      state.values.sender.addressLine2 = action.payload.addressLine2;
      state.values.sender.addressLine3 = action.payload.addressLine3;
      state.values.sender.city = action.payload.city;
      state.values.sender.countyState = action.payload.countyState;
      state.values.sender.countryIsoCode = action.payload.countryIsoCode;
      state.values.sender.zip = action.payload.zip;
      state.values.sender.phone = action.payload.phone;
      state.values.sender.email = action.payload.email;
      state.values.sender.company = action.payload.company;
      state.values.sender.eoriNumber = action.payload.eoriNumber;
      state.values.sender.vatNumber = action.payload.vatNumber;
    },

    [CreateShipmentSliceActions.ADD_CONTENTS_ROW]: (
      state,
      action: PayloadAction<{
        packageId: number;
        defaultSettings: ISalesAccountDefaults;
      }>
    ) => {
      const newItemRow = {
        ...DEFAULT_ITEM_CONTENT,
        packageId: action.payload.packageId,
      };

      if (action.payload.defaultSettings?.countryOfOrigin) {
        newItemRow.countryOfOrigin =
          action.payload.defaultSettings.countryOfOrigin;
      }

      if (action.payload.defaultSettings?.manufacturerDetails) {
        newItemRow.manufacturerDetails =
          action.payload.defaultSettings.manufacturerDetails;
      }

      if (action.payload.defaultSettings?.itemsCurrency) {
        newItemRow.currency = action.payload.defaultSettings.itemsCurrency;
      }

      state.values.contents = [...state.values.contents, newItemRow];

      state.errors.contents = [
        ...state.errors.contents,
        { ...DEFAULT_ITEM_CONTENT_ERRORS },
      ];

      state.touched.contents = [
        ...state.touched.contents,
        { ...DEFAULT_ITEM_CONTENT_TOUCHED },
      ];
    },

    [CreateShipmentSliceActions.REMOVE_CONTENTS_ROW]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.values.contents = current(state.values.contents).filter(
        (item, index) => index !== action.payload
      );

      state.touched.contents = current(state.touched.contents).filter(
        (item, index) => index !== action.payload
      );

      state.errors.contents = current(state.errors.contents).filter(
        (item, index) => index !== action.payload
      );
    },

    [CreateShipmentSliceActions.SET_SENDER_ADDRESS_TYPE]: (
      state,
      action: PayloadAction<CreateShipmentAddressType>
    ) => {
      state.values.sender.type = action.payload;
      state.values.sender.editModeSavedAddress = false;
    },

    [CreateShipmentSliceActions.SET_SAVED_SENDER_ADDRESS_EDIT_MODE]: (
      state
    ) => {
      state.values.sender.editModeSavedAddress =
        !state.values.sender.editModeSavedAddress;
    },

    [CreateShipmentSliceActions.SET_SAVED_RECEIVER_ADDRESS_EDIT_MODE]: (
      state
    ) => {
      state.values.receiver.editModeSavedAddress =
        !state.values.receiver.editModeSavedAddress;
    },

    [CreateShipmentSliceActions.CLEAR_SENDER_ADDRESS]: (state) => {
      state.values.sender.id = null;
    },

    [CreateShipmentSliceActions.CLEAR_RECEIVER_ADDRESS]: (state) => {
      state.values.receiver.id = null;
    },

    [CreateShipmentSliceActions.ON_TOUCH_ITEM_FIELD]: (
      state,
      action: PayloadAction<{ id: number; key: keyof IShipmentPackageGrouped }>
    ) => {
      const clonedItems = [...state.touched.items];

      const item = clonedItems[action.payload.id];

      if (item && item[action.payload.key]) item[action.payload.key] = true;

      state.touched.items = clonedItems;
    },

    [CreateShipmentSliceActions.SET_RECEIVER_ADDRESS_TYPE]: (
      state,
      action: PayloadAction<CreateShipmentAddressType>
    ) => {
      state.values.receiver.type = action.payload;
      state.values.receiver.editModeSavedAddress = false;
    },

    [CreateShipmentSliceActions.SET_RECEIVER_SAVED_ADDRESS]: (
      state,
      action: PayloadAction<IAddress>
    ) => {
      state.values.receiver.id = action.payload.id;
      state.values.receiver.firstName = action.payload.firstName;
      state.values.receiver.lastName = action.payload.lastName;
      state.values.receiver.addressLine1 = action.payload.addressLine1;
      state.values.receiver.addressLine2 = action.payload.addressLine2;
      state.values.receiver.addressLine3 = action.payload.addressLine3;
      state.values.receiver.city = action.payload.city;
      state.values.receiver.countyState = action.payload.countyState;
      state.values.receiver.zip = action.payload.zip;
      state.values.receiver.countryIsoCode = action.payload.countryIsoCode;
      state.values.receiver.phone = action.payload.phone;
      state.values.receiver.email = action.payload.email;
      state.values.receiver.company = action.payload.company;
      state.values.receiver.eoriNumber = action.payload.eoriNumber;
      state.values.receiver.vatNumber = action.payload.vatNumber;
    },

    [CreateShipmentSliceActions.ON_CHANGE_SENDER_ADDRESS_FIELD]: (
      state,
      action: PayloadAction<Partial<CreateShipmentAddress>>
    ) => {
      state.values.sender = { ...state.values.sender, ...action.payload };
    },

    [CreateShipmentSliceActions.SET_RECIPIENT_ADDRESS_ZIP]: (
      state,
      action: PayloadAction<CreateShipmentAddress["zip"]>
    ) => {
      state.values.receiver = { ...state.values.receiver, zip: action.payload };
    },

    [CreateShipmentSliceActions.SET_SENDER_ADDRESS_ZIP]: (
      state,
      action: PayloadAction<CreateShipmentAddress["zip"]>
    ) => {
      state.values.sender = { ...state.values.sender, zip: action.payload };
    },

    [CreateShipmentSliceActions.ON_CHANGE_SENDER_COUNTRY]: (
      state,
      action: PayloadAction<
        ICreateShipmentSlice["values"]["sender"]["countryIsoCode"]
      >
    ) => {
      state.values.sender.countryIsoCode = action.payload;
      state.values.sender.countyState = "";
    },

    [CreateShipmentSliceActions.ON_TOUCH_SENDER_ADDRESS_FIELD]: (
      state,
      action: PayloadAction<Partial<keyof CreateAddress>>
    ) => {
      state.touched.sender = {
        ...state.touched.sender,
        [action.payload]: true,
      };
    },

    [CreateShipmentSliceActions.ON_CHANGE_RECEIVER_ADDRESS_FIELD]: (
      state,
      action: PayloadAction<Partial<CreateShipmentAddress>>
    ) => {
      state.values.receiver = { ...state.values.receiver, ...action.payload };
    },

    [CreateShipmentSliceActions.ON_CHANGE_RECEIVER_COUNTRY]: (
      state,
      action: PayloadAction<
        ICreateShipmentSlice["values"]["receiver"]["countryIsoCode"]
      >
    ) => {
      state.values.receiver.countryIsoCode = action.payload;
      state.values.receiver.countyState = "";
    },

    [CreateShipmentSliceActions.ON_TOUCH_RECEIVER_ADDRESS_FIELD]: (
      state,
      action: PayloadAction<keyof CreateAddress>
    ) => {
      state.touched.receiver = {
        ...state.touched.receiver,
        [action.payload]: true,
      };
    },

    [CreateShipmentSliceActions.ON_CHANGE_DETAILS_FIELD]: (
      state,
      action: PayloadAction<Partial<ICreateShipmentSlice["values"]["details"]>>
    ) => {
      state.values.details = { ...state.values.details, ...action.payload };
    },

    [CreateShipmentSliceActions.ADD_PACKAGE]: (
      state,
      action: PayloadAction<ISalesAccountDefaults>
    ) => {
      state.values.items = [
        ...state.values.items,
        {
          ...DEFAULT_PACKAGE,
        },
      ];

      state.touched.items = [
        ...state.touched.items,
        {
          ...DEFAULT_PACKAGE_TOUCHED,
        },
      ];

      state.errors.items = [
        ...state.errors.items,
        {
          ...DEFAULT_PACKAGE_ERRORS,
        },
      ];

      // use standard values

      const content = {
        ...DEFAULT_ITEM_CONTENT,
        packageId: state.values.items.length - 1,
      };

      if (action.payload?.countryOfOrigin) {
        content.countryOfOrigin = action.payload.countryOfOrigin;
      }

      if (action.payload?.itemsCurrency) {
        content.currency = action.payload.itemsCurrency;
      }

      if (action.payload?.manufacturerDetails) {
        content.manufacturerDetails = action.payload.manufacturerDetails;
      }

      state.values.contents = [...state.values.contents, content];

      state.errors.contents = [
        ...state.errors.contents,
        {
          ...DEFAULT_ITEM_CONTENT_ERRORS,
        },
      ];

      state.touched.contents = [
        ...state.touched.contents,
        {
          ...DEFAULT_ITEM_CONTENT_TOUCHED,
        },
      ];

      // set default values for package dimensions

      const defaultSettings = action.payload;
      if (defaultSettings?.useDefaultPackageDimensionsInUi) {
        state.values.items = state.values.items.map((item) => ({
          ...item,
          height: defaultSettings.height || 0,
          length: defaultSettings.length || 0,
          width: defaultSettings.width || 0,
        }));
      }
    },

    [CreateShipmentSliceActions.SET_PACKAGES]: (
      state,
      action: PayloadAction<IShipmentPackageGrouped[]>
    ) => {
      state.values.items = action.payload;
    },

    [CreateShipmentSliceActions.SET_ITEMS]: (
      state,
      action: PayloadAction<CreateShipmentContents[]>
    ) => {
      state.values.contents = action.payload;
    },

    [CreateShipmentSliceActions.SET_RATES]: (
      state,
      action: PayloadAction<ICreateShipmentSlice["rates"]>
    ) => {
      // order rates by name
      action.payload.sort((a, b) => {
        if (a.carrier.name < b.carrier.name) return -1;
        if (a.carrier.name > b.carrier.name) return 1;
        return 0;
      });
      state.rates = action.payload;
    },

    [CreateShipmentSliceActions.REMOVE_PACKAGE]: (
      state,
      action: PayloadAction<number>
    ) => {
      const findFirstContentsForPackage = findIndex(
        state.values.contents,
        (item) => item.packageId === action.payload
      );

      const findLastContentsForPackage = findLastIndex(
        state.values.contents,
        (item) => item.packageId === action.payload
      );

      state.touched.contents = state.touched.contents.splice(
        findFirstContentsForPackage,
        findLastContentsForPackage
      );

      state.errors.contents = state.errors.contents.splice(
        findFirstContentsForPackage,
        findLastContentsForPackage
      );

      state.values.contents = state.values.contents.filter(
        (content) => content.packageId !== action.payload
      );

      state.values.items = state.values.items.filter(
        (item, index) => index !== action.payload
      );

      state.touched.items = state.touched.items.filter(
        (item, index) => index !== action.payload
      );

      state.errors.items = state.errors.items.filter(
        (item, index) => index !== action.payload
      );
    },

    [CreateShipmentSliceActions.UPDATE_PACKAGE_VALUE]: (
      state,
      action: PayloadAction<{
        id: number;
        value: string | number;
        field: keyof IShipmentPackage;
      }>
    ) => {
      const { id, value, field } = action.payload;

      const p = state.values.items[id];

      if (p) {
        state.values.items[id] = { ...p, [field]: value };
      }
    },

    [CreateShipmentSliceActions.ON_CHANGE_CONTENTS_FIELD]: (
      state,
      action: PayloadAction<{
        id: number;
        value: string | number;
        field: keyof CreateShipmentContents;
      }>
    ) => {
      const { id, value, field } = action.payload;

      const item = state.values.contents[id];

      if (item) {
        state.values.contents[id] = { ...item, [field]: value };
      }
    },

    [CreateShipmentSliceActions.ON_TOUCH_CONTENTS_FIELD]: (
      state,
      action: PayloadAction<{ id: number; key: keyof CreateShipmentContents }>
    ) => {
      const clonedItems = [...state.touched.contents];

      const item = clonedItems[action.payload.id];

      if (item && item[action.payload.key]) item[action.payload.key] = true; //https://app.bugsnag.com/itd-global/connexx-frontend/errors/65659e62cc3ef90008eb42f1?event_id=6566ef5300cdc4c788360000&i=sk&m=oc

      state.touched.contents = clonedItems;
    },

    [CreateShipmentSliceActions.SET_DEFAULT_PACKAGE_DIMENSIONS]: (
      state,
      action: PayloadAction<{ defaultSettings: ISalesAccountDefaults }>
    ) => {
      const defaultSettings = action.payload.defaultSettings;
      if (defaultSettings?.useDefaultPackageDimensionsInUi) {
        state.values.items = state.values.items.map((item) => ({
          ...item,
          height: defaultSettings.height || 0,
          length: defaultSettings.length || 0,
          width: defaultSettings.width || 0,
        }));
      }
    },

    [CreateShipmentSliceActions.SET_DEFAULT_ITEM_VALUE_INITIAL_STATE]: (
      state,
      action: PayloadAction<{ defaultSettings: ISalesAccountDefaults }>
    ) => {
      console.log("SET_DEFAULT_ITEM_VALUE_INITIAL_STATE");
      if (!state.editingShipmentJobNumber) {
        // don't set default values if editing shipment
        if (action.payload?.defaultSettings?.countryOfOrigin) {
          state.values.contents[0].countryOfOrigin =
            action.payload.defaultSettings.countryOfOrigin;
        }

        if (action.payload.defaultSettings?.manufacturerDetails) {
          state.values.contents[0].manufacturerDetails =
            action.payload.defaultSettings.manufacturerDetails;
        }

        if (action.payload?.defaultSettings?.itemsCurrency) {
          state.values.contents[0].currency =
            action.payload.defaultSettings.itemsCurrency;

          state.insurance.insuranceAmountCurrency =
            action.payload.defaultSettings.itemsCurrency;
        }
      }
    },

    [CreateShipmentSliceActions.SET_FILES]: (
      state,
      action: PayloadAction<
        { fileType: string; label: string; file: File | null }[]
      >
    ) => {
      state.files = action.payload;
    },

    [CreateShipmentSliceActions.SET_CURRENT_QUOTE_REFERENCE]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentQuoteReference = action.payload;
    },

    setDocumentOption: (
      state,
      action: PayloadAction<{ label: string; value: number }>
    ) => {
      state.documentOption = action.payload;
    },

    setDangerousGoodsOption: (
      state,
      action: PayloadAction<{ label: string; value: number }>
    ) => {
      state.dangerousGoodsOption = action.payload;
    },

    setDuplicateOrEdit: (state, action: PayloadAction<string>) => {
      state.duplicateOrEdit = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      isModalClosed,
      (state, action: { payload: { modalName: modals } }) => {
        if (action.payload.modalName === "createShipment") {
          Object.assign(state, CreateShipmentInitialState);
        }
      }
    );

    builder.addMatcher(
      isModalOpened,
      (state, action: { payload: { name: modals } }) => {
        if (action.payload.name === "createShipment") {
          state.values.sender.countryIsoCode = DEFAULT_FROM_COUNTRY_ISO_CODE;
          state.values.receiver.countryIsoCode = DEFAULT_FROM_COUNTRY_ISO_CODE;
          state.pickUpObject = DEFAULT_PICKUP_VALUES;
          state.dropOffObject = DEFAULT_DROPOFF_VALUES;
        }
      }
    );

    builder.addMatcher(isCountryChanged, (state) => {
      if (
        state.errors.sender.zip &&
        !isZipRequired(state.values.sender.countryIsoCode)
      ) {
        state.errors.sender.zip = "";
      }

      if (
        state.errors.receiver.zip &&
        !isZipRequired(state.values.receiver.countryIsoCode)
      ) {
        state.errors.receiver.zip = "";
      }
    });
  },
});

export const {
  addPackage,
  addContentsRow,
  removeContentsRow,
  setIsConvertingFromQuote,
  removePackage,
  setEditingID,
  setEditingUUID,
  setRatesLoadingStatus,
  setSalesAccountUUID,
  setSelectedRate,
  setSenderAddressType,
  setSenderSaveToAddressBook,
  setSubmittingErrorMessage,
  setSubmittingStatus,
  setSubmitCount,
  setErrors,
  setRatesError,
  setRecipientSaveToAddressBook,
  clearSenderAddress,
  clearReceiverAddress,
  setConvertingFromQuoteStatus,
  setPrintLater,
  setRates,
  setFormType,
  setPackages,
  setItems,
  setEditingJobNumber,
  setValues,
  setRecipientAddressZip,
  setSenderAddressZip,
  setReceiverAddressType,
  setReceiverSavedAddress,
  onChangeReceiverAddressField,
  onChangeReceiverCountry,
  onChangeSenderCountry,
  setSenderSavedAddress,
  onTouchItemField,
  onTouchReceiverAddressField,
  onTouchSenderAddressField,
  onTouchContentsField,
  onChangeSenderAddressField,
  onChangeContentsField,
  onChangeDetailsField,
  updatePackageValue,
  setPickUp,
  setDropOff,
  setSelectedLocation,
  setDropOffLocations,
  setSendingToResidence,
  setPickUpDate,
  setPickUpFromTime,
  setPickUpToTime,
  setPickUpInstructions,
  setDefaultItemValueInitialState,
  setDefaultFromISO_GA,
  setFiles,
  setSavedReceiverAddressEditMode,
  setSavedSenderAddressEditMode,
  setDocumentOption,
  setDangerousGoodsOption,
  setCurrentQuoteReference,
  setDisclaimerChecked,
  setUseInsurance,
  setInsuranceAmount,
  setInsuranceCurrency,
  setDefaultFromAddress,
  SET_DEFAULT_PACKAGE_DIMENSIONS,
  setDuplicateOrEdit,
} = createShipmentSlice.actions;

export default createShipmentSlice.reducer;

export const setDropOffThunk = (
  sender: CreateAddress,
  salesAccountUUID: string
): AppThunk => {
  return async (dispatch) => {
    try {
      const answer = await fetchShipmentDropOffLocations(
        sender,
        salesAccountUUID
      );
      const dropOffLocations = answer.data.output.locationDetailList.map(
        (element) => {
          const address = element.contactAndAddress.address;
          const distance = `${element.distance.value} ${element.distance.units}`;
          const carrierDetailList = element.carrierDetailList;
          const locationDetail = element;
          return { address, distance, carrierDetailList, locationDetail };
        }
      );

      dispatch(setDropOffLocations(dropOffLocations));
    } catch (error) {
      console.error("Error fetching drop-off locations:", error);
    }
  };
};
