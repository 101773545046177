import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { openModal, closeModal } from "store/modalSlice";
import { statuses } from "enum/shipment-statuses.enum";
import { Tooltip } from "components/atoms/Tooltip";
import { isLabelGeneratingForShipment } from "utils/isLabelGeneratingForShipment";
import { Button } from "components/atoms/Button";
import { ButtonDropdownGroup } from "components/atoms/ButtonDropdownGroup";
import { DropdowButton } from "components/atoms/DropdownButton";

import { Stack } from "components/atoms/Stack";
import { ViewShipmentModalTestIds } from "../ViewShipmentTestIds.enum";
import { editShipmentThunk } from "store/createShipmentSlice/thunks/editShipmentThunk.thunk";
import { isShipmentEditable } from "../utils/isShipmentEditable.util";
import { isShipmentPrintable } from "../utils/isShipmentPrintable.util";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { createSingleLabelThunk } from "store/createSingleLabelSlice/thunks/createSingleLabelThunk.thunk";
import { createSingleLabelBase64Thunk } from "store/createSingleLabelSlice/thunks/createSingleLabelBase64Thunk.thunk";
import { createCommercialInvoiceBase64Thunk } from "store/createSingleLabelSlice/thunks/createCommercialInvoiceBase64Thunk.thunk";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";

export const ViewShipmentButtons = () => {
  const {
    loadingStatus: createSingleLabelLoadingStatus,
    loadingCommercialInvoiceStatus,
  } = useSelector((state: RootState) => {
    return state.createSingleLabelSlice;
  });

  const { alertType, loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const permissions = new PermissionCheck({ user: loggedInUser });

  const isOnStop = alertType === "onstop";

  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const { currentTimeUTC } = useSelector((state: RootState) => {
    return state.tickerSlice;
  });

  const dispatch = useDispatch();

  const handleEdit = (duplicateOrError: string) => {
    if (isOnStop) {
      dispatch(openModal({ name: "customerOnStopWarning" }));
      return;
    } else {
      dispatch(editShipmentThunk(duplicateOrError));
    }
  };

  const handlePrint = () => {
    if (isOnStop) {
      dispatch(openModal({ name: "customerOnStopWarning" }));
    } else {
      dispatch(
        createSingleLabelThunk(
          currentShipment.id,
          currentShipment.status === statuses.ready_for_print
            ? "new"
            : "combine"
        )
      );
    }
  };

  const handlePrintBase64 = () => {
    if (isOnStop) {
      dispatch(openModal({ name: "customerOnStopWarning" }));
    } else {
      dispatch(createSingleLabelBase64Thunk(currentShipment.id));
    }
  };

  const handleCommercialInvoicePrintBase64 = () => {
    dispatch(
      createCommercialInvoiceBase64Thunk(
        currentShipment.id,
        currentShipment.customerReference
      )
    );
  };

  const isEditable = isShipmentEditable(currentShipment, currentTimeUTC);

  const isPrintable = isShipmentPrintable(currentShipment);

  return (
    <div>
      <Stack spacing={4}>
        <div className={`grid grid-cols-2 gap-4`}>
          <Tooltip
            content={
              currentShipment?.status === statuses.error
                ? "You can only print a label for a shipment when all errors have been corrected"
                : "A label is currently being created for this shipment. You cannot generate a new label for this shipment until the current one has been processed."
            }
            triggerClassNames="w-full"
            isDisabled={isPrintable}
          >
            <ButtonDropdownGroup
              options={[
                {
                  name: "Print labels in browser",
                  onClick: handlePrintBase64,
                },
              ]}
              isLoading={
                createSingleLabelLoadingStatus === ApiLoadingStatus.LOADING
              }
              colorScheme="primary"
              isDisabled={
                !isPrintable ||
                currentShipment.status === "cancelled" ||
                !permissions.shipments.canGenerate()
              }
              dropdownIsDisabled={
                !isPrintable ||
                currentShipment?.status !== statuses.label_created
              }
              onClick={handlePrint}
            >
              Print labels
            </ButtonDropdownGroup>
          </Tooltip>
          <Tooltip
            content={
              currentShipment?.status === statuses.error
                ? "You can only print a commercial invoice for a shipment when all errors have been corrected"
                : "A label is currently being created for this shipment. You cannot print a commercial invoice for this shipment until the current one has been processed."
            }
            triggerClassNames="w-full"
            isDisabled={isPrintable}
          >
            <DropdowButton
              options={[
                {
                  name: "Commercial Invoice",
                  onClick: handleCommercialInvoicePrintBase64,
                },
              ]}
              isLoading={
                loadingCommercialInvoiceStatus === ApiLoadingStatus.LOADING
              }
              colorScheme="default"
              isDisabled={
                !isPrintable ||
                currentShipment?.status !== statuses.label_created
              }
            >
              Download Documents
            </DropdowButton>
          </Tooltip>
        </div>
        <div className={`grid grid-cols-3 gap-4`}>
          <Tooltip
            content={
              isLabelGeneratingForShipment(currentShipment)
                ? "You can't edit a shipment whilst a label is generating for it. Please try again later."
                : "You can no longer edit this shipment."
            }
            triggerClassNames="w-full"
            isDisabled={isEditable}
          >
            <Button
              testId={
                ViewShipmentModalTestIds.VIEW_SHIPMENT_EDIT_SHIPMENT_BUTTON
              }
              isBlock
              onClick={() => handleEdit("EDIT")}
              size="lg"
              isDisabled={
                !isEditable ||
                currentShipment.status === "label_created" ||
                currentShipment.status === "cancelled"
              }
            >
              Edit shipment
            </Button>
          </Tooltip>
          <Button
            testId={ViewShipmentModalTestIds.VIEW_SHIPMENT_EDIT_SHIPMENT_BUTTON}
            isBlock
            onClick={() => handleEdit("DUPLICATE")}
            size="lg"
            isDisabled={!isEditable}
          >
            Duplicate shipment
          </Button>
          <Button
            testId={
              ViewShipmentModalTestIds.VIEW_SHIPMENT_CANCEL_SHIPMENT_BUTTON
            }
            isBlock
            size="lg"
            isDisabled={
              !isEditable ||
              currentShipment.status === "cancelled" ||
              !permissions.shipments.canDelete()
            }
            onClick={() => {
              dispatch(openModal({ name: "confirmCancelShipment" }));
              dispatch(closeModal({ modalName: "viewShipment" }));
            }}
          >
            Cancel shipment
          </Button>
        </div>
      </Stack>

      {/* {currentShipment.status === statuses.label_created && (
        <div
          className="mt-3 text-md text-center text-muted"
          data-testid={
            ViewShipmentModalTestIds.VIEW_SHIPMENT_CUTOFF_TIME_MESSAGE
          }
        >
          {isLabelCancellable(
            currentShipment.packages,
            parseISO(currentTimeUTC)
          ) ? (
            <>{getCutoffMessage(currentShipment.packages)}</>
          ) : (
            <>You can no longer cancel this shipment</>
          )}
        </div>
      )} */}
    </div>
  );
};
