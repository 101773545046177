import { Loader } from "components/atoms/Loader";
import { ModalBody, ModalHeader } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { openModal } from "store/modalSlice";
import { Button } from "components/atoms/Button";
import { formatDate } from "utils/formatDate";
import dayjs from "dayjs";
import { Data } from "components/organisms/Data";
import { PickupViewColumns } from "./PickupViewColumns";

export const ViewPickupModal = () => {
  const { selectedShipment, loadingStatus, meta, query } = useSelector(
    (state: RootState) => {
      return state.pickupSlice;
    }
  );

  const dispatch = useDispatch();

  return (
    <>
      <ModalHeader>
        {selectedShipment?.isCancelled ? "(CANCELLED)" : ""}{" "}
        {selectedShipment?.prn}
      </ModalHeader>
      <ModalBody>
        <div>Address: {selectedShipment?.address}</div>
        <div>Carrier: {selectedShipment?.carrier}</div>
        {/* <div>Created date: {formatDate(selectedShipment?.createdDate)}</div> */}
        {selectedShipment?.isCancelled ? null : (
          <>
            <div>
              Pick up date/time:{" "}
              {dayjs(
                new Date(selectedShipment?.pickUpFromDateLocalTime)
              ).format("DD MMM YYYY")}{" "}
              - From:{" "}
              {dayjs(
                new Date(selectedShipment?.pickUpFromDateLocalTime)
              ).format("HH:mm")}{" "}
              {">"} To:{" "}
              {dayjs(new Date(selectedShipment?.pickUpToDateLocalTime)).format(
                "HH:mm"
              )}
            </div>
            <div>
              Pickup Instructions: {selectedShipment?.pickUpInstructions}
            </div>
          </>
        )}
        <div className="flex flex-col mt-4">
          <Data
            meta={{
              ...meta,
            }}
            data={selectedShipment?.shipments}
            columns={PickupViewColumns}
            onNextPage={(page) => {
              // dispatch(setQueryOffset(page));
            }}
            onPrevPage={(page) => {
              // dispatch(setQueryOffset(page));
            }}
            loadingStatus={loadingStatus}
            sort={query.sort}
            handleColumnSort={(sort) => {
              // dispatch(setQuerySort(sort));
            }}
            // onRefresh={handleRefresh}
            manualPagination={false}
          />
        </div>
        {selectedShipment?.isCancelled ? null : (
          <div className="mt-4">
            <Button
              isBlock
              onClick={() => {
                dispatch(
                  openModal({
                    name: "confirmCancelPickup",
                  })
                );
              }}
              size="lg"
              isDisabled={false}
              colorScheme="primary"
            >
              Cancel Pickup
            </Button>
          </div>
        )}
      </ModalBody>
    </>
  );
};
