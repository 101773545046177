import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { SortDirection } from "interfaces/api.interface";
import { DEFAULT_META } from "constants/misc";
import { Meta, Query } from "interfaces/api.interface";
import { cloneDeep } from "lodash";
import { AppThunk } from "store";
import {
  fetchEligiblePickUpShipmentsThunk,
  fetchShipmentsWithPickupThunk,
} from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";

export type IPickupSliceFilters = {
  search?: string;
  salesAccountId?: number;
  fromDate?: string;
  toDate?: string;
};

export type IPickupShipment = {
  address: string;
  containsPickup: boolean;
  createdDate: string;
  prnNumber: string;
  quantity: number;
  recepient: string;
  reference: string;
  selected: boolean;
  service: string;
  shipmentId: string;
  tracking: string;
  weight: string;
};

export type IPickupAddressObj = {
  Address: string;
  hide: boolean;
  id: number;
  shipments: IPickupShipment[];
};

export type IPickupData = {
  ups: IPickupAddressObj[];
  fedex: IPickupAddressObj[];
};

export type Sort = { createdDate: string };

export interface IPickupSlice {
  loadingStatus: ApiLoadingStatus;
  createLoadingStatus: ApiLoadingStatus;
  data: IPickupData;
  query: Query<Sort, IPickupSliceFilters>;
  meta: Meta;
  viewPickupQuery: Query<Sort, IPickupSliceFilters>;
  viewPickupmMeta: Meta;
  createPickupDateRangeDays: "1d" | "7d" | "30d" | "1y" | "None";
  viewPickupDateRangeDays: "1d" | "7d" | "30d" | "1y" | "None";
  pickupDate: string;
  pickupFromTime: string;
  pickupToTime: string;
  selectedPickUpID: null | number;
  shipmentsWithPickup: any[];
  selectedShipment: any;
  pickupInstructions: string;
}

const fromDate = new Date();

const toDate = new Date();

const addHours = (date, hours) => {
  date.setHours(date.getHours() + hours);

  return date;
};

const initialState: IPickupSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  createLoadingStatus: ApiLoadingStatus.IDLE,
  data: null,
  query: {
    offset: 0,
    filters: {
      salesAccountId: null,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    },
    sort: {
      createdDate: SortDirection.DESC,
    },
  },
  meta: { ...DEFAULT_META },
  viewPickupQuery: {
    offset: 0,
    filters: {
      salesAccountId: null,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    },
    sort: {
      createdDate: SortDirection.DESC,
    },
  },
  viewPickupmMeta: { ...DEFAULT_META },
  createPickupDateRangeDays: "1d",
  viewPickupDateRangeDays: "1d",
  pickupDate: new Date().toISOString(),
  pickupFromTime: new Date().toISOString(),
  pickupToTime: addHours(new Date(), 4).toISOString(),
  selectedPickUpID: null,
  shipmentsWithPickup: [],
  selectedShipment: null,
  pickupInstructions: "",
};

export const pickupSlice = createSlice({
  name: "tableSlice",
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<{ data: IPickupData; meta: Meta }>
    ) => {
      state.data = action.payload.data || initialState.data;

      state.meta = action.payload.meta || initialState.meta;
    },

    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },

    setCreateStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.createLoadingStatus = action.payload;
    },

    setShipmentsWithPickup: (state, action: PayloadAction<any[]>) => {
      state.shipmentsWithPickup = action.payload;
    },

    setSelectedShipment: (state, action: PayloadAction<any>) => {
      state.selectedShipment = action.payload;
    },

    setCreatePickupQueryOffset: (state, action: PayloadAction<number>) => {
      state.query = {
        ...state.query,
        offset: action.payload,
      };
    },

    setCreatePickupQuerySort: (
      state,
      action: PayloadAction<IPickupSlice["query"]["sort"]>
    ) => {
      state.query.sort = action.payload;
    },

    setCreatePickupQuerySearch: (
      state,
      action: PayloadAction<IPickupSliceFilters["search"]>
    ) => {
      if (state.query.filters) state.query.filters.search = action.payload;
    },

    setCreatePickupQueryFromDate: (
      state,
      action: PayloadAction<IPickupSlice["query"]["filters"]["fromDate"]>
    ) => {
      state.query.filters.fromDate = action.payload;
    },

    setCreatePickupQueryToDate: (
      state,
      action: PayloadAction<IPickupSlice["query"]["filters"]["toDate"]>
    ) => {
      state.query.filters.toDate = action.payload;
    },

    setCreatePickupDateRangeDays: (
      state,
      action: PayloadAction<IPickupSlice["createPickupDateRangeDays"]>
    ) => {
      state.createPickupDateRangeDays = action.payload;
    },

    setViewPickupQueryOffset: (state, action: PayloadAction<number>) => {
      state.viewPickupQuery = {
        ...state.viewPickupQuery,
        offset: action.payload,
      };
    },

    setViewPickupQuerySort: (
      state,
      action: PayloadAction<IPickupSlice["viewPickupQuery"]["sort"]>
    ) => {
      state.viewPickupQuery.sort = action.payload;
    },

    setViewPickupQuerySearch: (
      state,
      action: PayloadAction<IPickupSliceFilters["search"]>
    ) => {
      if (state.viewPickupQuery.filters)
        state.viewPickupQuery.filters.search = action.payload;
    },

    setViewPickupQueryFromDate: (
      state,
      action: PayloadAction<
        IPickupSlice["viewPickupQuery"]["filters"]["fromDate"]
      >
    ) => {
      state.viewPickupQuery.filters.fromDate = action.payload;
    },

    setViewPickupQueryToDate: (
      state,
      action: PayloadAction<
        IPickupSlice["viewPickupQuery"]["filters"]["toDate"]
      >
    ) => {
      state.viewPickupQuery.filters.toDate = action.payload;
    },

    setViewPickupDateRangeDays: (
      state,
      action: PayloadAction<IPickupSlice["viewPickupDateRangeDays"]>
    ) => {
      state.viewPickupDateRangeDays = action.payload;
    },

    setAddressVisibility: (
      state,
      action: PayloadAction<{ carrier: string; address: string; data }>
    ) => {
      let newData = cloneDeep(action.payload.data);

      const index = newData[action.payload.carrier]
        .map((e) => e.Address)
        .indexOf(action.payload.address);
      newData[action.payload.carrier][index].hide =
        !newData[action.payload.carrier][index].hide;

      if (!newData[action.payload.carrier][index].hide) {
        newData[action.payload.carrier][index].shipments = newData[
          action.payload.carrier
        ][index].shipments.map((element) => {
          return { ...element, selected: true };
        });
      } else {
        newData[action.payload.carrier][index].shipments = newData[
          action.payload.carrier
        ][index].shipments.map((element) => {
          return { ...element, selected: false };
        });
      }
      state.data = newData;
    },
    setShipmentSelection: (
      state,
      action: PayloadAction<{ shipmentId: string; selected: boolean }>
    ) => {
      const fedex = state.data.fedex.map((element) => {
        const shipments = element.shipments.map((shipment) => {
          let value = { ...shipment };
          if (value.shipmentId === action.payload.shipmentId) {
            value = { ...shipment, selected: action.payload.selected };
          }
          return value;
        });
        return { ...element, shipments };
      });
      const ups = state.data.ups.map((element) => {
        const shipments = element.shipments.map((shipment) => {
          let value = { ...shipment };
          if (value.shipmentId === action.payload.shipmentId) {
            value = { ...shipment, selected: action.payload.selected };
          }
          return value;
        });
        return { ...element, shipments };
      });

      state.data = { ups, fedex };
    },
    setShipmentSelectionAll: (
      state,
      action: PayloadAction<{ shipmentId: string; bool: boolean }>
    ) => {
      const fedex = state.data.fedex.map((element) => {
        let shipments = [...element.shipments];
        if (
          element.shipments.some(
            (obj) => obj.shipmentId === action.payload.shipmentId
          )
        ) {
          shipments = element.shipments.map((shipment) => {
            return { ...shipment, selected: action.payload.bool };
          });
        }
        return { ...element, shipments };
      });
      const ups = state.data.ups.map((element) => {
        let shipments = [...element.shipments];
        if (
          element.shipments.some(
            (obj) => obj.shipmentId === action.payload.shipmentId
          )
        ) {
          shipments = element.shipments.map((shipment) => {
            return { ...shipment, selected: action.payload.bool };
          });
        }
        return { ...element, shipments };
      });

      state.data = { ups, fedex };
    },
    setHideAll: (state, action: PayloadAction<{ data: IPickupData }>) => {
      let newData = cloneDeep(action.payload.data);
      newData.fedex = newData?.fedex.map((element) => {
        let value = { ...element, hide: true };
        value.shipments = value.shipments.map((shipment) => {
          return { ...shipment, selected: false };
        });
        return { ...value };
      });
      newData.ups = newData?.ups.map((element) => {
        let value = { ...element, hide: true };
        value.shipments = value.shipments.map((shipment) => {
          return { ...shipment, selected: false };
        });
        return { ...value };
      });
      state.data = newData;
    },
    setPickUpDate: (state, action: PayloadAction<string>) => {
      state.pickupDate = action.payload;
      const newDate = action.payload.split("T")[0];
      const fromTime = state.pickupFromTime.split("T")[1];
      const toTime = state.pickupToTime.split("T")[1];
      state.pickupFromTime = newDate + "T" + fromTime;
      state.pickupToTime = newDate + "T" + toTime;
    },
    setPickUpToTime: (state, action: PayloadAction<string>) => {
      state.pickupToTime = action.payload;
    },
    setPickUpFromTime: (state, action: PayloadAction<string>) => {
      state.pickupFromTime = action.payload;
    },
    setSelectedPickUpID: (state, action: PayloadAction<number>) => {
      state.selectedPickUpID = action.payload;
    },
    setPickupInstructions: (state, action: PayloadAction<any>) => {
      state.pickupInstructions = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      isModalClosed,
      (state, action: { payload: { modalName: modals } }) => {
        if (action.payload.modalName === "pickUpRequestModal") {
          state.pickupDate = new Date().toISOString();
          state.pickupFromTime = new Date().toISOString();
          state.pickupToTime = addHours(new Date(), 4).toISOString();
          state.selectedPickUpID = null;
          state.pickupInstructions = "";
          state.createLoadingStatus = ApiLoadingStatus.IDLE;
        }
        if (action.payload.modalName === "pickUpModal") {
          state.query.filters.fromDate = fromDate.toISOString();
          state.query.filters.toDate = toDate.toISOString();
          state.createPickupDateRangeDays = "1d";
        }
        if (action.payload.modalName === "viewPickupModal") {
          state.selectedShipment = null;
        }
      }
    );
  },
});

export const {
  setData,
  setStatus,
  setCreateStatus,
  setCreatePickupQueryOffset,
  setCreatePickupQuerySort,
  setCreatePickupQuerySearch,
  setCreatePickupQueryFromDate,
  setCreatePickupQueryToDate,
  setCreatePickupDateRangeDays,
  setViewPickupQueryOffset,
  setViewPickupQuerySort,
  setViewPickupQuerySearch,
  setViewPickupQueryFromDate,
  setViewPickupQueryToDate,
  setViewPickupDateRangeDays,
  setAddressVisibility,
  setShipmentSelection,
  setShipmentSelectionAll,
  setHideAll,
  setPickUpDate,
  setPickUpToTime,
  setPickUpFromTime,
  setSelectedPickUpID,
  setShipmentsWithPickup,
  setSelectedShipment,
  setPickupInstructions,
} = pickupSlice.actions;

export const changeCreatePickupDateRangeDaysThunk = (
  dateRangeDay: "1d" | "7d" | "30d" | "1y" | "None"
): AppThunk => {
  return async (dispatch) => {
    dispatch(setCreatePickupDateRangeDays(dateRangeDay));
    let fromDate = new Date();
    const toDate = new Date();
    const isLeapYear = (year) => new Date(year, 1, 29).getMonth() === 1;
    if (dateRangeDay === "1d") {
      fromDate = new Date();
    } else if (dateRangeDay === "7d") {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (dateRangeDay === "30d") {
      fromDate.setDate(fromDate.getDate() - 30);
    } else if (dateRangeDay === "1y") {
      fromDate.setDate(
        fromDate.getDate() - (isLeapYear(toDate.getFullYear()) ? 366 : 365)
      );
    }
    await dispatch(setCreatePickupQueryFromDate(fromDate.toISOString()));
    await dispatch(setCreatePickupQueryToDate(toDate.toISOString()));
    dispatch(fetchEligiblePickUpShipmentsThunk());
  };
};

export const changeViewPickupDateRangeDaysThunk = (
  dateRangeDay: "1d" | "7d" | "30d" | "1y" | "None"
): AppThunk => {
  return async (dispatch) => {
    dispatch(setViewPickupDateRangeDays(dateRangeDay));
    let fromDate = new Date();
    const toDate = new Date();
    const isLeapYear = (year) => new Date(year, 1, 29).getMonth() === 1;
    if (dateRangeDay === "1d") {
      fromDate = new Date();
    } else if (dateRangeDay === "7d") {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (dateRangeDay === "30d") {
      fromDate.setDate(fromDate.getDate() - 30);
    } else if (dateRangeDay === "1y") {
      fromDate.setDate(
        fromDate.getDate() - (isLeapYear(toDate.getFullYear()) ? 366 : 365)
      );
    }
    await dispatch(setViewPickupQueryFromDate(fromDate.toISOString()));
    await dispatch(setViewPickupQueryToDate(toDate.toISOString()));
    dispatch(fetchShipmentsWithPickupThunk());
  };
};
