import { CellProps, Column } from "react-table";
import { IShipment } from "./shipment.model";
import { TableTextCell } from "components/atoms/Table/TableTextCell";
import { formatDate } from "utils/formatDate";
import { useDispatch } from "react-redux";
import { setQueryBulkShipment } from "store/shipmentsSlice/index.slice";
import { setFilterSidebarOpenStatus } from "store/tableSlice/index.slice";
import { TableLinkCell } from "components/atoms/Table/TableLinkCell";
import { TableAddressCell } from "components/atoms/Table/cells/TableAddressCell";
import { TableLogoCell } from "components/atoms/Table/cells/TableCellLogo";
import { getLogoUrl } from "utils/getLogoUrl";

export const ShipmentColumns: Column<IShipment>[] = [
  {
    Header: "Reference",
    accessor: "customerReference",

    Cell: (props: CellProps<IShipment>) => {
      return <TableTextCell value={props.row.original.customerReference} />;
    },
  },
  {
    Header: "Reference 2",
    accessor: "customerReference2",

    Cell: (props: CellProps<IShipment>) => {
      return <TableTextCell value={props.row.original.customerReference2} />;
    },
  },
  {
    Header: "Order Number",
    accessor: "orderNumber",

    Cell: (props: CellProps<IShipment>) => {
      return <TableTextCell value={props.row.original.orderNumber} />;
    },
  },
  {
    Header: "PRN Number",
    accessor: "upsPickupPrnNumber",

    Cell: (props: CellProps<IShipment>) => {
      return (
        <TableTextCell
          value={
            props.row.original?.upsPickupPrnNumber
              ? props.row.original?.upsPickupPrnNumber
              : props.row.original?.fedexPickupPrnNumber
              ? props.row.original?.fedexPickupPrnNumber
              : ""
          }
        />
      );
    },
  },
  {
    Header: "Sku",
    accessor: "sku",

    Cell: (props: CellProps<IShipment>) => {
      const itemSkusInCurrentShipment = [];
      props.row.original.itemDescriptions.forEach((element) => {
        if (
          element.sku &&
          !itemSkusInCurrentShipment.includes(element.sku) &&
          element.shipmentId === props.row.original.id
        ) {
          itemSkusInCurrentShipment.push(element.sku);
        }
      });
      const sku = [];
      itemSkusInCurrentShipment.forEach((element) => {
        if (!sku.includes(element)) {
          sku.push(element);
        }
      });
      return <TableTextCell value={sku.toString()} />;
    },
  },
  {
    Header: "Description",
    accessor: "description",

    Cell: (props: CellProps<IShipment>) => {
      const itemDescriptionsInCurrentShipment = [];
      props.row.original.itemDescriptions.forEach((element) => {
        if (
          element.description &&
          !itemDescriptionsInCurrentShipment.includes(element.description) &&
          element.shipmentId === props.row.original.id
        ) {
          itemDescriptionsInCurrentShipment.push(element.description);
        }
      });
      const description = [];
      itemDescriptionsInCurrentShipment.forEach((element) => {
        if (!description.includes(element)) {
          description.push(element);
        }
      });
      return <TableTextCell value={description.toString()} />;
    },
  },
  {
    Header: "Package Quantity",
    accessor: "packageQuantity",

    Cell: (props: CellProps<IShipment>) => {
      return (
        <TableTextCell
          value={props.row.original.packages?.length?.toString()}
        />
      );
    },
  },
  {
    Header: "Store Name",
    accessor: "integrationStoreId",

    Cell: (props: CellProps<IShipment>) => {
      return <TableTextCell value={props.row.original.integrationStoreName} />;
    },
  },
  {
    Header: "From",
    accessor: "fromAddressFirstName",

    Cell: (props: CellProps<IShipment>) => {
      return (
        <TableAddressCell
          firstName={props.row.original.fromAddressFirstName}
          lastName={props.row.original.fromAddressLastName}
          company={props.row.original.fromAddressCompany}
          zip={props.row.original.fromAddressZip}
          countryIsoCode={props.row.original.fromAddressCountryIso}
        />
      );
    },
  },
  {
    Header: "To",
    accessor: "toAddressFirstName",
    Cell: (props: CellProps<IShipment>) => {
      return (
        <TableAddressCell
          firstName={props.row.original.toAddressFirstName}
          lastName={props.row.original.toAddressLastName}
          company={props.row.original.toAddressCompany}
          zip={props.row.original.toAddressZip}
          countryIsoCode={props.row.original.toAddressCountryIso}
        />
      );
    },
  },
  {
    Header: "Created at",
    accessor: "createdDate",
    enableSorting: true,
    Cell: (props: CellProps<IShipment>) => {
      return (
        <TableTextCell value={formatDate(props.row.original.createdDate)} />
      );
    },
  },
  {
    Header: "Carrier",
    accessor: (originalRow) => {
      return originalRow.carrier?.name;
    },
    Cell: (props: CellProps<IShipment>) => {
      return (
        <TableLogoCell
          label={props.row.original.carrier?.name}
          logoUrl={getLogoUrl(props.row.original.carrier?.name)}
        />
      );
    },
  },
  {
    Header: "Service",
    accessor: (originalRow) => {
      return originalRow.service?.name;
    },
    Cell: (props: CellProps<IShipment>) => {
      return (
        <div
          className="inline-flex items-center px-2 text-xs font-medium leading-none rounded-md bg-gray4 text-gray10"
          style={{ paddingTop: 5, paddingBottom: 4 }}
        >
          <div
            className="mr-1 rounded-full bg-gray10"
            style={{ width: 6, height: 6 }}
          />
          {props.row.original.service?.name}
        </div>
      );
    },
  },
  {
    Header: "Created by",
    accessor: (originalRow) => {
      return originalRow.shipmentBulkUploadId;
    },
    Cell: (props: CellProps<IShipment>) => {
      const dispatch = useDispatch();
      return (
        <>
          {props.row.original.bulkUpload ? (
            <TableLinkCell
              value={props.row.original.bulkUpload.filename}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setFilterSidebarOpenStatus(true));
                dispatch(setQueryBulkShipment(props.row.original.bulkUpload));
              }}
            />
          ) : (
            <TableTextCell value={props.row.original.createdByUser?.email} />
          )}
        </>
      );
    },
  },
];
