export type Meta<T = {}> = {
  offset: number;
  limit: number;
  pageCount: number;
  recordCount: number;
} & T;

export type ApiLoadingStatus = "IDLE" | "LOADING" | "SUCCESS" | "FAILED";

export type PaginatedList<T, M> = { data: T } & { meta: Meta<M> };

export enum SortDirection {
  "ASC" = "ASC",
  "DESC" = "DESC",
}

export type QuerySort<Model> = {
  [key in keyof Model]?: SortDirection;
};

export type Query<Model = {}, Filters = {}> = {
  offset?: number;
  sort?: QuerySort<Model>;
  limit?: number;
  filters?: Filters;
};
