import { useState } from "react";
import { ModalBody } from "components/atoms/Modal";
import { ModalHeader } from "components/atoms/Modal/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { Panel } from "components/atoms/Panel";
import { Stack } from "components/atoms/Stack";
import { Divider } from "components/atoms/Divider";
import { Tabs } from "components/atoms/Tabs";
import { Data } from "components/organisms/Data";
import {
  setCreatePickupQueryOffset,
  setCreatePickupQuerySort,
  setCreatePickupQuerySearch,
  setAddressVisibility,
  setHideAll,
  setSelectedPickUpID,
  setPickUpDate,
  setPickUpFromTime,
  setPickUpToTime,
  changeCreatePickupDateRangeDaysThunk,
} from "store/pickupSlice/index.slice";
import { fetchEligiblePickUpShipmentsThunk } from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";
import { changeCreateDateRangeFilterThunk } from "store/pickupSlice/thunks/changeDateRangeFilterThunk";
import { DatePickerComponent } from "components/atoms/datePicker/antdDatePicker";
import { NumberOfDaysComponent } from "components/atoms/datePicker/numberOfDaysPicker";
import { FormControl } from "components/molecules/FormControl";
import dayjs from "dayjs";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Button } from "components/atoms/Button";
import { useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { Center } from "components/atoms/Center";
import { CSSProperties } from "react";
import { PickupBaseColumns } from "./PickupBaseColumns";
import { openModal } from "store/modalSlice";

export const PickupModal = (): JSX.Element => {
  const dispatch = useDispatch();

  const { theme } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  const { meta, query, loadingStatus, data } = useSelector(
    (state: RootState) => {
      return state.pickupSlice;
    }
  );

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const fromDate = query.filters.fromDate
    ? dayjs(new Date(query.filters.fromDate))
    : null;

  const toDate = query.filters.toDate
    ? dayjs(new Date(query.filters.toDate))
    : null;

  const [tabs, setTabs] = useState([
    {
      name: "UPS",
      current: true,
      onClick: () => {
        changeTabs("UPS");
      },
    },
    {
      name: "FedEx",
      current: false,
      onClick: () => {
        changeTabs("FedEx");
      },
    },
  ]);

  const changeTabs = (tab: string) => {
    const newTabs = tabs.map((element) => {
      let value = { ...element };
      if (element.name === tab) {
        value.current = true;
      } else {
        value.current = false;
      }
      return value;
    });

    setTabs(newTabs);
  };

  const changeData = (carrier, address) => {
    dispatch(setAddressVisibility({ carrier, address, data }));
  };

  useEffect(() => {
    dispatch(fetchEligiblePickUpShipmentsThunk());
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(setHideAll({ data }));
    }
  }, [tabs]);

  const handleRefresh = () => {
    dispatch(fetchEligiblePickUpShipmentsThunk());
  };
  return (
    <>
      <ModalHeader>Create a new pick up</ModalHeader>
      <ModalBody>
        <Tabs tabs={tabs} />
        <div className="flex flex-row mt-8">
          <div className="flex flex-col w-1/6 mr-4">
            <NumberOfDaysComponent
              todayOnChange={() => {
                dispatch(changeCreatePickupDateRangeDaysThunk("1d"));
              }}
              sevenDayOnChange={() => {
                dispatch(changeCreatePickupDateRangeDaysThunk("7d"));
              }}
              monthOnchange={() => {
                dispatch(changeCreatePickupDateRangeDaysThunk("30d"));
              }}
              yearOnChange={() => {
                dispatch(changeCreatePickupDateRangeDaysThunk("1y"));
              }}
              location={"createPickups"}
            />
            <div className="w-full mt-4">
              <FormControl label="From" isRequired={false} className={"w-full"}>
                <DatePickerComponent
                  onChange={(from) => {
                    dispatch(
                      changeCreateDateRangeFilterThunk(
                        from["$d"].toISOString(),
                        query.filters.toDate
                      )
                    );
                  }}
                  value={fromDate}
                />
              </FormControl>
            </div>
            <div className="flex mt-4 w-full">
              <FormControl label="To" isRequired={false} className={"w-full"}>
                <DatePickerComponent
                  onChange={(to) => {
                    dispatch(
                      changeCreateDateRangeFilterThunk(
                        query.filters.fromDate,
                        to["$d"].toISOString()
                      )
                    );
                  }}
                  value={toDate}
                />
              </FormControl>
            </div>
          </div>
          <div className="flex flex-row w-5/6">
            {loadingStatus === "LOADING" ? (
              <Center>
                <PulseLoader
                  color={theme === "dark" ? "#ffffff" : "#000000"}
                  loading={true}
                  cssOverride={override}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Center>
            ) : null}
            {loadingStatus === "SUCCESS" && data ? (
              <>
                {tabs.map((tab) => {
                  return (
                    <>
                      {tab.current ? (
                        <div className={`w-full ml-2`}>
                          {data[tab.name.toLowerCase()].length ? (
                            <>
                              {data[tab.name.toLowerCase()].map((element) => {
                                let containsPrnNumber = false;
                                element.shipments.forEach((shipment) => {
                                  if (shipment.prnNumber && shipment.selected) {
                                    containsPrnNumber = true;
                                  }
                                });

                                return (
                                  <Panel className="flex flex-col mb-8">
                                    <Stack spacing={0} divider={<Divider />}>
                                      <div className={"py-5 flex flex-col"}>
                                        <div className="flex flex-row justify-between mx-5">
                                          <div className="flex">
                                            {element.hide ? (
                                              <ChevronRightIcon
                                                aria-hidden="true"
                                                className="h-10 w-10 text-gray-400 mr-2 self-center cursor-pointer"
                                                onClick={() => {
                                                  changeData(
                                                    tab.name.toLowerCase(),
                                                    element.Address
                                                  );
                                                }}
                                              />
                                            ) : (
                                              <ChevronDownIcon
                                                aria-hidden="true"
                                                className="h-10 w-10 text-gray-400 mr-2 self-center cursor-pointer"
                                                onClick={() => {
                                                  changeData(
                                                    tab.name.toLowerCase(),
                                                    element.Address
                                                  );
                                                }}
                                              />
                                            )}
                                            <div className="text-2xl font-bold">
                                              {element.Address}
                                            </div>
                                          </div>
                                          {!element.hide && (
                                            <div className="self-center">
                                              <Button
                                                isBlock
                                                onClick={() => {
                                                  dispatch(
                                                    setSelectedPickUpID(
                                                      element.id
                                                    )
                                                  );

                                                  const addHours = (
                                                    date,
                                                    hours
                                                  ) => {
                                                    date.setHours(
                                                      date.getHours() + hours
                                                    );

                                                    return date;
                                                  };
                                                  dispatch(
                                                    setPickUpDate(
                                                      new Date().toISOString()
                                                    )
                                                  );
                                                  dispatch(
                                                    setPickUpFromTime(
                                                      new Date().toISOString()
                                                    )
                                                  );
                                                  dispatch(
                                                    setPickUpToTime(
                                                      addHours(
                                                        new Date(),
                                                        4
                                                      ).toISOString()
                                                    )
                                                  );
                                                  dispatch(
                                                    openModal({
                                                      name: "pickUpRequestModal",
                                                    })
                                                  );
                                                }}
                                                size="lg"
                                                isDisabled={false}
                                                colorScheme={
                                                  containsPrnNumber
                                                    ? "selected"
                                                    : "primary"
                                                }
                                              >
                                                Request Pickup
                                              </Button>
                                            </div>
                                          )}
                                        </div>
                                        {!element.hide ? (
                                          <div className="flex flex-col mt-4">
                                            <Data
                                              meta={{
                                                ...meta,
                                              }}
                                              data={element.shipments}
                                              columns={PickupBaseColumns}
                                              onSearchSubmit={(query) => {
                                                dispatch(
                                                  setCreatePickupQuerySearch(
                                                    query
                                                  )
                                                );
                                              }}
                                              onNextPage={(page) => {
                                                dispatch(
                                                  setCreatePickupQueryOffset(
                                                    page
                                                  )
                                                );
                                              }}
                                              onPrevPage={(page) => {
                                                dispatch(
                                                  setCreatePickupQueryOffset(
                                                    page
                                                  )
                                                );
                                              }}
                                              loadingStatus={loadingStatus}
                                              sort={query.sort}
                                              handleColumnSort={(sort) => {
                                                dispatch(
                                                  setCreatePickupQuerySort(sort)
                                                );
                                              }}
                                              onRefresh={handleRefresh}
                                              manualPagination={false}
                                              allowExport
                                              hideDownload
                                              hideSearch
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    </Stack>
                                  </Panel>
                                );
                              })}
                            </>
                          ) : (
                            <div>
                              No Shipments appear to be eligible for pickup
                              within the selected time frame.
                            </div>
                          )}
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      </ModalBody>
    </>
  );
};
