import { Stack } from "components/atoms/Stack";
import { DEFAULT_WEIGHT_UNIT } from "constants/misc";
import { WeightUnits, WeightUnitSettings } from "interfaces/weight-units.type";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  calculateTotalChargeableWeight,
  calculateTotalItems,
  calculateTotalWeight,
  calculateTotalShipmentValue,
} from "utils/calculateWeights";
import {
  convertDatabaseToValue,
  convertGramsToKilos,
} from "utils/convertGramsToKilos";
import { convertKilosToPounds } from "utils/convertPoundsToKilos";
import { PackagesTestIds } from "./CreateShipmentFormPackagesTestIds.enum";
import { useMemo } from "react";

const TotalRow = ({
  label,
  value,
  testId,
}: {
  label: string;
  value: string | number;
  testId?: string;
}) => {
  return (
    <div className="flex items-center text-sm">
      <div className="underline decoration-dotted underline-offset-8 decoration-gray8">
        {label}
      </div>

      <div className="ml-auto" {...(testId && { "data-testid": testId })}>
        {value}
      </div>
    </div>
  );
};

export const CreateShipmentFormPackagesTotals = () => {
  const { values, selectedRate } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { financialCompany } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  const divideTotalPackageWeightByQuantityInUi =
    settings?.divideTotalPackageWeightByQuantityInUi;

  const selectedRateCarrierName = selectedRate?.carrier?.name;

  const chargeableWeight = useMemo(() => {
    return (
      calculateTotalChargeableWeight(
        values.items,
        selectedRateCarrierName,
        divideTotalPackageWeightByQuantityInUi
      ) || 0
    );
  }, [values.items, selectedRateCarrierName]);

  const { contents } = values;

  const totalPackages = calculateTotalItems(values.items) || 0;

  return financialCompany !== 8 ? (
    <Stack spacing={3}>
      <TotalRow
        testId={PackagesTestIds.PACKAGES_TOTAL_PACKAGES}
        label="Total packages"
        value={totalPackages}
      />
      <TotalRow
        testId={PackagesTestIds.PACKAGES_TOTAL_WEIGHT}
        label="Total weight"
        value={`${convertDatabaseToValue(
          settings?.weightUnit === WeightUnits.pound
            ? convertKilosToPounds(
                convertGramsToKilos(
                  calculateTotalWeight(
                    values.items,
                    divideTotalPackageWeightByQuantityInUi
                  ) || 0
                )
              )
            : convertGramsToKilos(
                calculateTotalWeight(
                  values.items,
                  divideTotalPackageWeightByQuantityInUi
                ) || 0
              )
        )}${
          WeightUnitSettings[settings?.weightUnit]?.label ||
          WeightUnitSettings[DEFAULT_WEIGHT_UNIT].label
        }`}
      />
      <TotalRow
        testId={PackagesTestIds.PACKAGES_TOTAL_CHARGEABLEWEIGHT}
        label="Total chargeable weight"
        value={`${
          settings?.weightUnit === WeightUnits.pound
            ? convertKilosToPounds(convertGramsToKilos(chargeableWeight))
            : convertGramsToKilos(chargeableWeight)
        }${
          WeightUnitSettings[settings?.weightUnit]?.label ||
          WeightUnitSettings[DEFAULT_WEIGHT_UNIT].label
        }`}
      />
      <TotalRow
        testId={PackagesTestIds.PACKAGES_TOTAL_PACKAGES}
        label="Total shipment value"
        value={calculateTotalShipmentValue(values, totalPackages)}
      />
    </Stack>
  ) : (
    <> </>
  );
};
